
.status-inner-content
{
	.battery-section {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin-top: 15px;
		margin-bottom: 12px;

	}
	.last-seen-section {
		&.last-seen-info-section {
			.status {
				&:after {
					top: 2px;
				}
			}
		}
		.last-seen-content {
			.timezone{
				font-weight: 500;
				font-size: 12px;
				line-height: 140%;
				display: block;
				width: 100%;
				color: #8899B4;
				margin-top:5px;
			}
			@include fontStyles($color-body-text-2,14px,normal);
			text-transform: capitalize;
		}
	}
	.battery-with-percentage {
		@include displayFlex($justifyContent:flex-start);
		span{
			@include fontStyles($color-text-secondary,14px,normal,400);

		}
	}
	.address-section{
		margin:0px 0px 12px;
		@include displayFlex($justifyContent:flex-start, $alignItems: flex-start);
		.img-section{
			margin-right: 11px;
			top:3px;
			position: relative;
		}
		.text-section{
			@include fontStyles($color-text-secondary,14px,normal);
			flex:1;
			min-height: 36px;
			padding-right: 10px;
		}
		.address-connection{
			@include fontStyles($color-body-text-1, 14px, normal);
			// max-width: 220px;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			// overflow: hidden;
			// text-overflow: ellipsis;
			// white-space: nowrap;
			// margin-left:15px;
		}
	}
	.devices-function {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		border-bottom: 1px solid rgba($border-color,0.4);
		padding: 15px 0px;
		@media screen and (max-width:600px) {
			justify-content: flex-start;
		}
		.device-btn {
			display: flex;
			align-items: center;
			flex-direction: row;
			padding: 9px 20px 9px 6px;
			border-radius: 24px;
			height: unset;
			background: $address-form;
			margin-right: 2px;
			margin-bottom: 3px;
			position: relative;
			width: 49%;
			font-size: 14px;
			font-weight: 500;
			line-height: normal;
			color: #021021;
			text-transform: capitalize;
			.tooltip-info-btn{
				position: absolute;
				top:7px;
				right: 7px;
			}
			@include fontStyles($color-text-secondary,14px,normal);
			text-transform: capitalize;


			span {
				margin: 0 5px;
				font-size: 12px;
				flex-grow: 1;
				text-align: center;
				font-size: 14px;
			}
			.custom-p {
				padding-right: 15px;
			}
		}
		.device-btn-img {
			width: 31px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 31px;
			border-radius: 20px;
			background: $color-secondary;
		}
	}

	.ping-device-btn
	{

		span{
			margin-left: 5px;
		}
	}
}
.status-accordion {
	&.MuiAccordion-root {
		background-color: transparent;
		margin: 0;
		padding: 0;
		border: 0;
		box-shadow: none;
		color:$color-dark;
		&::before{
			opacity:1;
		}
	}
	&.replay-status-accordion {
		&.MuiAccordion-root {
			&::before {
				opacity: 0;
			}
		}
	}
	.MuiSvgIcon-root{
		color:$color-text-secondary;
		width: 30px;
		height: 30px;
		display: none;
	}
	.status-section {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-items: center;
		width: 100%;
		margin-bottom: 10px;
		.status {
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
			align-items: center;
			border-radius: 10px;
			background: $address-form;
			width: 100%;
			min-height: 42px;
			position: relative;
			margin-right: 8px;
			padding-left: 7px;
			&:last-child {
				margin-right: 0;
			}
			&.status-selected{
				color:#ffffff;
				background: #F13737;
			}
			.status-img {
				display: flex;
				margin-right: 8px;
				// margin-bottom: 10px;
				.img-fluid {
					width: 24px;
				}
				&.light-sensor-img {
					margin-bottom: 5px;
					padding-top: 10px;
				}
			}
			.thermo-img {
				width: 13px;
				margin-right: 8px;
			}
			.tooltip {
				position: absolute;
				top: 5px;
				right: 5px;
			}
			.sensor-text {
				font-size: 12px;
				font-weight: 500;
			}
		}
	}
	.MuiAccordionSummary-content {
		&.Mui-expanded {
			margin: 12px 0px;
			@include fontStyles($color-text-secondary,14px);
		}
	}
	.MuiAccordionDetails-root {
		padding: 0;
	}
}
.status-accordion .MuiAccordionSummary-root.Mui-expanded, .status-accordion .MuiAccordionSummary-root {
	padding: 0;
	min-height: unset;
}
.notes-section {
	display: flex;
	flex-direction: column;
	border-top: 1px solid rgba($border-color,0.4);
	padding: 15px 0px 2px;
	width: 100%;
	.title {
		color: $color-text-secondary;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-bottom: 10px;
	}
	.notes-textarea {
		border-radius: 10px;
		min-height: 26px;
		padding: 15px 15px 10px;
		background: #F8F8F8;
		border: none;
		outline: none;
		color: $color-dark;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		width: 100%;
		line-height: 140%;
		resize: none;
		&::placeholder {
			color: $placeholder-color;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 140%;
		}
	}
	.char-count {
		color: $color-body-text-1;
		font-size: 12px;
		align-self: flex-end;
	}
}
.edit-interval-modal {
	width: 100%;
	.modal-body {
		.MuiAccordionSummary-content {
			margin: 5px 0;
		}
		.MuiAccordion-root {
			background-color: transparent;
			border: 0;
			box-shadow: none;
			margin: 0;
			padding: 0;
			color:$color-dark;
		}
		.MuiSvgIcon-root{
			color:$color-text-secondary;
			width: 30px;
			height: 30px;
		}
		.MuiAccordionSummary-root {
			padding: 0;
			&.Mui-expanded {
				min-height: unset;
			}
		}
	}
	.MuiAccordionSummary-content {
		svg {
			margin: 0;
		}
	}
	.MuiAccordionDetails-root {
		padding: 0;
	}
}
.speed-interval-section {
	.interval-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: 400;
		justify-content: space-between;
		border-top: 1px solid rgba($border-color,0.4);
		width: 100%;
		padding: 5px 0px;
		margin-bottom: 5px;
		.text-section {
			flex-direction: row;
			display: flex;
			align-items: baseline;
		}
	}
	.speed-section {
		.text-section {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}
	.section-label {
		letter-spacing: 0px;
		color: $color-text-secondary;
		font-weight: 400;
		font-size: 16px;
	}
	.device-section-label {
		letter-spacing: 0px;
		color: $color-text-secondary;
		font-weight: 400;
		font-size: 14px;
		padding: 7px 20px;
	}
	.value {
		color: $color-secondary;
		font-size: 18px;
		margin-top: 1px;
		font-weight: bold;
		text-transform: lowercase;
	}
}
.interval-title {
	color: $color-text-secondary;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: -5px;
}
.speed-img,.interval-img {
	height: 23px;
	margin-right: 15px;
}
.speed-interval-section .speed-section .img-section, .speed-interval-section .interval-section .img-section {
	margin-right: 10px;
	border-radius: 50%;
	width: 44px;
	height: 43px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.speed-interval-section .speed-section .img-section img, .speed-interval-section .interval-section .img-section img {
	width: 30px;
	height: 19px;
}
.status-card-btn {
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	flex-direction: column;
	display: flex;
	.header {
		background-color: transparent !important;
		box-shadow: none !important;
		padding: 0rem !important;
		text-transform: capitalize;
		margin-bottom: 0.5rem;
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: #FFFFFF;
		svg {
			font-size: 1.55rem;
			transform: rotate(90deg);
		}
	}
	.device-status {
		.status {
			margin-top: 0px;
		}
	}
	.MuiButtonBase-root {
		.MuiIconButton-root {
			top: 6px;
			padding: 0;
		}
	}
	.status-card-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: center;
		background-color: transparent;
		margin-bottom: 15px;
	}
}
.address-section {
	.img-section {
		margin-right: 17px;
		height: 25px;
	}
}
.inner-content {
	border-top: 1px solid #ffffff29;
	border-bottom: 1px solid #ffffff29;
	padding-bottom: 0.5rem;
	padding-top: 0.25rem;
	padding-left: 0.8rem;
	padding-right: 0.8rem;
}
.speed-interval-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 12px;
	.speed-section {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
}
.all-tracker-checkbox{
	margin-right: 0;
	.MuiCheckbox-root{
		padding-right: 7px;
	}
}
.battery-img {
	margin-right: 6px;
}
.ping-device-btn {
	text-transform: capitalize;
	font-weight: 400;
	font-size: 14px;
	border-radius: 19.5px !important;
	background-color: $color-secondary !important;
	color: #FFFFFF;
	padding: 15px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mode-box {
	margin: 10px 0px;
	.MuiFormGroup-root {
		flex-direction: row;
		align-items: center;
	}
	.MuiSwitch-track {
		background-color: $color-secondary;
		opacity: 1;
	}
	.MuiSwitch-root {
		margin: 0px 15px;
	}
}
.mode-label,.mode-box .MuiFormControlLabel-label {
	color: $color-text-secondary;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.interval-section {
	.MuiSelect-select {
		&.MuiInputBase-input {
			&.MuiOutlinedInput-input {
				width: 6rem;
				background-color: transparent;
				box-shadow: none;
				padding-left: 0;
				padding-top: 3px;
				border: none;
				color: $color-secondary;
				font-size: 16px;
				margin-top: 1px;
			}
		}
	}
	.MuiInputBase-root {
		&.MuiOutlinedInput-root {
			margin-left: 3rem;
			height: 38px;
			border: 1px solid #ECEEF7;
			border-radius: 23px;
		}
	}
}
.interval-section .MuiInputBase-root.MuiOutlinedInput-root, .interval-section .MuiOutlinedInput-notchedOutline {
	border: none !important;
	outline: none !important;
}
.last-seen-section, .address-section {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.last-seen-section {
	.last-seen-icon {
		width: 14px;
		height: 14px;
		background: #DEC134 0% 0% no-repeat padding-box;
		border-radius: 48px;
		margin-right: 1.25rem;
	}
}
.icon-section {
	background-color: #1E1E1E !important;
	width: 42px;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	position: relative !important;
	margin: 0rem 0.85rem;
	&.rotating {
		animation: rotation 2s infinite linear;
	}
}

.MuiSnackbar-root {
	bottom: 70px;
	background: #E8F1FF 0% 0% no-repeat padding-box;
	border-radius: 27px;
	color: $color-secondary;
	padding: 0rem 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	svg {
		color: $color-secondary !important;
	}
}
.MuiAlert-filled {
	margin: 0px !important;
	color: $color-secondary;
	padding: 0rem 0.8rem;
	box-shadow: none;
}
.MuiAlert-message {
	letter-spacing: 0px;
	color: $color-secondary;
	font-weight: 500;
	font-size: 14px;
}
.selected-icons-section {
	span {
		text-transform: capitalize;
		letter-spacing: 0px;
		color: #D5D5D5;
		font-size: 14px;
	}
	.device-icon-detail {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		.device-img {
			width: 80px;
			height: 80px;
		}
		button {
			background: #343844 0% 0% no-repeat padding-box;
			border-radius: 32px !important;
			box-shadow: none;
			margin-top: 0.5rem;
			color: #FFFFFF;
			padding: 0px 13px;
			height: 38px;
			font-weight: 400;
			font-size: 13px;
			img {
				margin-right: 8px;
				width: 20px;
			}
			span {
				margin-top: 5px;
			}
		}
	}
}
.longitude-latitude {
	display: flex;
	flex-direction: column;
	margin: 15px 0px 0;
	div {
		display: flex;
		margin-bottom: 15px;
		align-items: center;
		flex-direction: row;
		font-size: 14px;
		img {
			margin-right: 12px;
		}
		span{
			@include fontStyles($color-text-secondary,14px,normal,400);
		}
	}
}
.light-sensor {
	display: flex;
	padding-bottom: 5px;
	align-items: center;
	flex-direction: row;
	font-size: 15px;
	margin-top: 10px;
	img {
		margin-right: 12px;
	}
	span{
		@include fontStyles($color-text-secondary,14px,normal,400);
	}
}
.time-period-input {
	width: 100%;
	margin: 10px 0px;
	svg{
		display: none;
	}
	.MuiSelect-select {
		background-color: $form-textfield-bg;
		border-radius: 5px;
		max-width: 100% !important;
		padding: 10px;
		position: relative;
		&:after{
			position: absolute;
			right:15px;
			content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none"><path d="M1 1L5.51413 5.59346C5.77179 5.85386 6.12019 6 6.48333 6C6.84648 6 7.19488 5.85386 7.45253 5.59346L11.9676 1" stroke="%23021021" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		}
		input{
			background-color: $form-textfield-bg !important;
			color: $form-filled-input-color !important;
			font-size: 14px;
			font-weight: $font-weight-medium;
			box-shadow: none;
			line-height: 140%;
			border-radius: 10px;
			border: none;
			padding: 12px;
			outline: none;
			&::placeholder{
				color:$placeholder-color;
			}
			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
				-webkit-text-fill-color: $form-filled-input-color;
				transition: background-color 5000s ease-in-out 0s;
				caret-color: $form-filled-input-color !important;
				&:hover {
					-webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
					-webkit-text-fill-color:$form-filled-input-color;
					transition: background-color 5000s ease-in-out 0s;
					caret-color: $form-filled-input-color !important;
				}
				&:focus {
					-webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
					-webkit-text-fill-color: $form-filled-input-color;
					transition: background-color 5000s ease-in-out 0s;
					caret-color: $form-filled-input-color !important;
				}
			}
		}
	}

}
.route-section {
	border-top: 1px solid rgba($border-color,0.4);
	// border-bottom: 1px solid rgba($border-color,0.4);
	padding: 8px 0px 18px;
	margin: 10px 0px;
	.MuiSlider-markLabel{
		top:8px;
		z-index: 2;

	}

	.MuiSlider-valueLabel{
		background-color: transparent  !important;
	}
	.MuiSlider-thumb {
		color: transparent;
		box-shadow: none !important;
		&::before{
			box-shadow: none;
		}
		&::after {
			content: url('../../../resources/images/icon-slider.svg');
			top: 12px;
			width: auto;
			height: auto;
		}
	}
	.MuiSlider-rail {
		color: #BBDAFF;
		border-radius: 7px;
	}

	.MuiSlider-root {
		color: $color-secondary;
		margin-top: 10px;
		width:100%;
		overflow: hidden;
		padding:13px 0px !important;
		&::before {
			content: " ";
			height: 10px;
			width: 10px;
			border-radius: 50%;
			background-color: #FFFFFF;
			border: 2px solid $color-secondary;
			position: absolute;
			top: 8px;
			z-index: 3;

		}
		&::after {
			content: " ";
			height: 10px;
			width: 10px;
			border-radius: 50%;
			background-color: #FFFFFF;
			border: 2px solid $color-secondary;
			position: absolute;
			top: 8px;
			z-index: 3;
			right: 0px;

		}
	}
	.playback-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		margin-top: 3px;
		background-color: rgba(202, 226, 255, 0.32);
		border-radius: 8px;
		padding:10px 8px;
		.controller {
			display: flex;
			justify-content: center;
			flex-grow: 1;
			padding-left: 45px;
			min-height: 25px;
			position: relative;
			&>svg {
				position: absolute;
				top: 4px;
				left: 4px;
			}
		}
		.timer {
			color: #8899B4;
			font-size: 12px;
			font-weight: 500;
			min-width: 46px;
			margin-right: auto;
			border-radius: 5px;
			text-align: left;
			border: 1px solid #D6E0F4;
			background: #fff;
			.MuiInputBase-input {
				font-size: 12px;
				padding: 0 !important;
				background: #fff;
				display: flex;
				align-items: center;
				padding-left: 5px !important;
				padding-top: 0px !important;
				line-height: 1.4;
			}
			.MuiSvgIcon-root {
				right: 0;
				top: 2px;
			}
		}
		button {
			padding: 0;
			height: auto;
			min-height: unset;
			box-shadow: none;
			min-width: 21px;
			background:none !important;
			box-shadow: none;
			transition: none;
			&.play-btn {
				min-width: 30px;
				min-height: 30px;
				svg {
					width: 100%;
					height: 28px;
				}
			}
		}
	}
}
ul.control-description {
	list-style: none;
	li {
		display: flex;
		margin-bottom: 6px;
		.control-icons {
			display: flex;
			margin-right: 5px;
			min-width: 59px;
			justify-content: center;
			svg {
				width: 13px;
				height: 17px;
				margin: 0 3px;
			}
			&.custom-h-w {
				svg {
					width: 17px;
					height: 17px;
				}
			}
		}
	}
}
.alert-box {
	padding-top: 20px;
	padding-right: 6px;
	padding-top: 15px;
	padding-right: 0px;

	.alert-top-header{
		background-color: rgba(119, 176, 246, 0.16);
		width:100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: row;
		border-radius: 15px;
		padding:10px;
		margin-bottom: 15px;
		gap:3px;
		p{
			@include fontStyles($color-body-text-2,12px,normal);
			flex:1;
		}
		.cross-btn{
			padding:0;
			min-width: unset;
			width:7px;
			height: 7px;
			position: relative;
			top:3px;
		}
	}
	.MuiFormControlLabel-root {
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row-reverse;
		border-bottom: 1px solid #C8D1E8 !important;
		padding: 10px 0px !important;
		&:last-child{
			border-bottom:0 !important;
		}

	}
	.MuiSwitch-track {
		background-color: #D5D5D5;
		opacity: 1;
	}
	.MuiTouchRipple-root {
		color: white !important;
	}
	.MuiFormControlLabel-label {
		font-size: 14px !important;
		margin-right: 2px;
	}
	.options-title {
		color: #BDC9E2;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;
		margin-top: 5px;
	}
}
.alert-box .MuiSwitch-root,.mode-box .MuiSwitch-root {
	height: 25px;
	border-radius: 20px;
	width: 45px;
	padding: 0;
	background-color: #045ecce6;
}
.alert-checkboxes {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	.alert-checkboxes-form {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}
	.MuiFormControlLabel-root {
		padding: 0;
		flex-direction: row;
		svg {
			display: none;
		}
	}
	.MuiFormControlLabel-label {
		color: $color-text-secondary;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
}
.alert-box .MuiSwitch-switchBase, .mode-box .MuiSwitch-switchBase {
	padding: 3px;
}
.alert-box .MuiSwitch-thumb, .mode-box .MuiSwitch-thumb {
	width: 18px;
	height: 19px;
	color: white;
}
.refresh-btn {
	border-radius: 0px !important;
	box-shadow: none  !important;
	transition: none   !important;
	color: #FFFFFF;
	text-transform: capitalize;
	font-weight: 400;
	min-width: auto  !important;
	margin-top: 0px;
	padding: 0 !important;
	background-color: transparent   !important;
}

.sensor-alerts-section {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
	max-height: 61vh;
	overflow-y: auto;
	padding-right: 6px;
	@media screen and (max-width:1800px) {
		max-height: 43.5vh;
	}
	@media screen and (max-width:600px) {
		max-height: 45vh;
	}

	.MuiFormControlLabel-root {
		border-bottom: 0;
		padding-bottom: 0;
		padding-top: 10px;
	}
	.sensor-detail {
		color: #BDC9E2;
		font-size: 11px;
		font-style: normal;
		font-weight: 500;
		line-height: 15px;
		max-width: 240px;
		margin-bottom: 0px;
	}
	.sensor-input {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid rgba($border-color,0.4);
		background-color:  #F4F6FB;
		border-radius: 25px;
		width: 99px;
		padding: 11px 15px;
		justify-content: center;
		margin-top: 10px;
		.unit {
			color: #A0B0D0;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.sensor-textfield {
		display: flex;
		input {
			padding: 0;
			color: #ffffff;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			background: transparent;
			width: unset;
			height: unset;
			border-radius: 0;
			text-align: center;
			text-align: center;
		}
	}
}
.sensor-form {
	border-bottom: 1px solid #ffffff29;
	padding-bottom: 0px;
}
.view-alert-btn {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	border-radius: 8px !important;
	border: 1px solid $color-secondary !important;
	margin-top: 10px;
	background-color: $color-secondary !important;
	height: auto;
	padding:12px 25px;
	min-width: auto;
	box-shadow: 0px 0px 40px -5px rgba(1, 11, 35, 0.20);
	span {
		color: $color-light;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: initial;
		margin-right: 8px;
	}
}
.edit-interval-modal {
	.modal-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btn {
			padding: 0 !important;
			text-transform: initial;
			font-weight: 400;
			color: #A4A4A4;
			font-size: 14px;
			box-shadow: none;
			transition: none;
			background-color: transparent !important;
			min-width: unset  !important;
		}
	}
	.save-btn {
		padding: 8px 25px !important;
		color: $color-light ;
		text-transform: capitalize;
		font-size: 14px;
		border-radius: 8px !important;
		font-weight: 400;
		background: $color-secondary;
		min-height: unset !important;
		height: auto;
	}
	.spinner-circle{
		width:auto;
		padding:7px 30px;
	}
	.MuiSlider-root {
		color: #BBDAFF !important;
		width: 88%;
		margin-left: 10px;
	}
	.MuiSlider-track {
		color: $color-secondary !important;
	}
	.MuiSlider-thumb {
		color:#BDC9E2  !important;
		width:12px;
		height: 12px;
	}
	.MuiSlider-valueLabel{
		left:0 !important;
		&::before{
			left: 6.5px;
		}
	}


}
.label-with-tooltip{
	display: flex;
	flex-direction: row;
	align-items: center;
	>span{
		margin-right: 5px ;
	}
}
.custom-marker{
	&:hover .marker-text {
		display: flex; // Show the .marker-text on hover
	}
	.marker-text{
		background-color: #F6F8FA;
		display: none;
		position: relative;
		top:-78px;
		left:-25px;
		flex-direction: row;
		align-items: center;
		gap:2px;
		color:#021021;
		font-weight: 500;
		line-height: 16.45px;
		border-radius: 4px;
		flex-wrap: wrap;
		min-width: 100px;
		justify-content: center;
		padding:6px;
		font-size: 14px;
		z-index: 10;
	}
}
.date-picker-container {
	position: relative;
	&.date-picker-replay-page {
		padding-top: 7px;
	}
	margin-bottom: 12px;
	.date-picker {
		display: flex;
		align-items: center;
		gap: 17px;
		background-color: $form-textfield-bg;
		padding:  15px;
		min-height:42px;
		border-radius: 10px;
		width: 100%;
		justify-content: space-between;
		cursor: pointer;

		p {
			color: #A0B0D0;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
		}

		svg {
			width: 14px;
			path{
				stroke: #A0B0D0;
			}
		}
		&.date-picker-mobile {
			display: none;
			@media (max-width: 767px) {
				display: flex;
			}
		}
		&.date-picker-desktop {
			display: flex;
			@media (max-width: 767px) {
				display: none;
			}
		}
	}

	.custom-datepicker {
		position: absolute;
		// left: -300px;
		left: 1px;
		top: 48px;
		z-index: 1000;

		.rdrDefinedRangesWrapper {
			display: none;
		}

		.rdrDateRangePickerWrapper {
			box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
			.rdrCalendarWrapper {
				.rdrMonths {
					.rdrInRange {
						color: #88b7f0 !important;
					}
				}
			}
		}

		@media (max-width: 1050px) {
			width: 100%;

			.rdrDateRangePickerWrapper {
				width: 100%;
				.rdrCalendarWrapper {
					width: 100%;
					.rdrMonths {
						width: 100%;
						.rdrMonth {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
.date-picker-modal {
	border: none;
	border-radius: 10px;
	width: 550px;
	min-height: fit-content;
	overflow: auto;
	padding: 12px 25px;
	background: #f6f8fa;
	@media (max-width: 600px) {
		width: fit-content;
		min-height: fit-content;
		padding: 12px 5px;
	}
	@media (max-width: 400px) {
		zoom: 95%;
	}
	@media (max-width: 374px) {
		zoom: 89%;
	}
	.inner-section {
		background: #fff;
		padding: 16px 11px;
		border-radius: 6px;
		@media (max-width: 767px) {
			padding: 16px 10px;
		}
		.save-range-btn {
			margin-left: auto;
			display: flex;
			min-width: 82px;
			height: 42px;
			color: #fff;
			font-size: 14px;
			border-radius: 8px;
			background: #1A74E2;
		}
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 10px;
		@media (max-width: 767px) {
			padding-left: 11px;
		}
	}
	&>button {
		position: absolute;
		right: 0;
		top: 2px;
		svg {
			font-size: 26px;
		}
	}
}

.device-listing-imei-wrapper {
	.device-listing-imei-section {
		background: #fff;
		border-radius: var(--section_radius);
		margin-bottom: 30px;
		.heading-section {
			display: flex;
			justify-content: end;
			align-items: center;
			.heading-wrapper {
				display: flex;
				svg {
					font-size: 24px;
					cursor: pointer;
				}
			}
			h4 {
				font-size: 18px;
				font-weight: 500;
				margin-right: 5px;
			}
			.input-wrapper {
				position: relative;
				margin-right: 28px;
				input {
					border: 1px solid #bdc9e2;
					width: 270px;
					color: #8899b4;
					min-height: 25px;
					border-radius: 50px;
					padding: 10px 0 10px 40px;
					font-size: 12px;
					padding-left: 10px !important;
					&:focus,
					&:active,
					&:focus-visible {
						outline:none;
					}
				}
				svg {
					position: absolute;
					left: 11px;
					top: 11px;
					font-size: 25px;
					color: #a0b0d0;
				}
				.chips-input {
					.MuiInputBase-root {
						padding: 0;
						background: none;
						max-width: 270px;
						input {
							&:focus,
							&:active,
							&:focus {
								background: none;
							}
						}
						.MuiButtonBase-root {
							.MuiSvgIcon-root {
								right: -10px;
								top: -9px;
								left: unset;
								font-size: 16px;
							}
						}
						.MuiAutocomplete-tag {
							max-height: 25px;
							.MuiChip-label {
								font-size: 10px;
							}
						}
					}
					.MuiAutocomplete-endAdornment {
						right: 22px;
						bottom: 7px;
						top: unset;
						.MuiButtonBase-root {
							.MuiSvgIcon-root {
								font-size: 21px !important;
								right: -15px !important;
								top: -14px !important;
							}
						}
					}
				}
			}
			.custom-pom-switch {
				.MuiSwitch-root {
					margin-right: 6px;
				}
			}
			.filter-section {
				display: flex;
				align-items: center;
				position: relative;
				svg {
					position: absolute;
					right: 11px;
					font-size: 23px;
					font-weight: 100;
					color: #8899b4;
				}
				label {
					font-size: var(--section_font_size);
					font-weight: var(--section_font_weight);
					margin-right: 12px;
				}
				select {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					height: 46px;
					width: 147px;
					border-radius: 50px;
					padding: 0 10px;
					font-size: var(--section_font_size);
					font-weight: var(--section_font_weight);
					border-color: #8899b4;
					color: #4f5661;
					&:focus,
					&:active,
					&:focus-visible {
						outline:none;
					}
					&::-ms-expand {
						display: none;
					}
				}
				.MuiInputBase-root:has(> .MuiSelect-select) {
					border-radius: 27px;
					svg {
						top: 13px;
						right: 5px;
					}
				}
			}
		}
		.tablelike {
			.tablelike__body {
				.record-list {
					.imei-section {
						width: inherit !important;
					}
					.status-pill {
						font-size: var(--section_font_size);
						font-weight: var(--section_font_weight);
						display: flex;
						align-items: center;
						justify-content: center;
						min-width: 98px;
						min-height: 34px;
						border-radius: 50px;
						text-transform: capitalize;
						&.pending {
							background: #FFF4E5;
							color: #FF8F00;
						}
						&.failed {
							background: #feefef;
							color: #F13737;
						}
					}
					.action-icons {
						display: flex;
						align-items: center;
						svg {
							margin-right: 6px;
							cursor: pointer;
						}
					}
					.send-text {
						font-size: var(--section_font_size);
						font-weight: var(--section_font_weight);
						color: #1A74E2;
						cursor: pointer;
					}
				}
			}
		}
	}
	.imei-tab-footer {
		background: #f6f8fa;
		padding: 19px 1px;
		button {
			font-size: 12px;
			border-radius: 8px;
			&.Mui-disabled {
				color: #2d2d2d;
			}
		}
	}
	.imei-tab-footer-command {
		padding: 19px 30px;
		.report-filter {
			margin-top: 15px;
			border: 1px solid rgba(0, 0, 0, 0.12);
		}
		button {
			margin-top: 15px;
			font-size: 12px;
			border-radius: 8px;
			&.Mui-disabled {
				color: #2d2d2d;
			}
		}
	}
	.pagination-section {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		.MuiPagination-ul {
			li  {
				button {
					color: #1a74e2 !important;
					border-color: #1a74e2 !important;
					border-radius: 10px;
					&.Mui-selected {
						color: #fff !important;
						background-color: #1a74e2 !important;
					}
					&:hover {
						color: #fff !important;
						background-color: #1a74e2 !important;
					}
				}
			}
		}
	}
}
.recenter-replay-wrapper {
	position: absolute;
	bottom: 6px;
	right: 30px;
	width: 42px;
	@media screen and (max-width:600px) {
		top: 209px;
		right: 14px;
		bottom: unset;
	}
}