.page-header {
  padding: 20px 0;

  @media screen and (max-width: 786px) {
    padding: 15px 0;

  }
  @media screen and (max-width: 600px) {
    padding:0px 0  15px 0;

  }
  &__title {
    font-size: 25px;
    font-weight: 500;
    display: inline-block;
    @media screen and (max-width: 786px) {
      font-size: 16px;
    }
  }

  &__subtitle {
    margin-top: 3px;
    color: $color-body-text-1;
    font-size: 14px;
    font-weight: 500;
    display: none;
    @media screen and (max-width: 1200px){
  max-width: 305px;
}
    @media screen and (max-width: 786px) {
      margin-top: 5px;
      max-width: 283px;
    }
    @media screen and (max-width: 610px) {
    
      max-width: unset;
    }
  }
}