.event-calendar {
    margin: 24px 0 14px auto;
    border: 1px solid #C8D1E8;
    border-radius: 8px;
    padding: 0 24px;

    @media screen and (max-width:600px) {
        padding: 0 8px;
    }

    * {
        box-shadow: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }



    button {
        width: 100% !important;
        background-color: #ffffff !important;
        color: #021021 !important;
        border: 1px solid #C8D1E8 !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px !important;
        opacity: 1 !important;
        margin-bottom: 7px !important;
        padding: 12px !important;

        &.go2556357651 {
            background-color: rgba(129, 185, 255, 0.22) !important;
            color: #1A74E2 !important;
            border: 1px solid transparent !important;
        }
    }

    .rsm-cancel-button {
        font-size: 14px !important;
        margin-left: 8px !important;
        height: 45px;
        display: none !important;
    }

    .top {
        background: none !important;
    }

    .has-selection {
        button {
            width: 100% !important;
            background-color: #ffffff !important;
            color: #021021 !important;
            border: 1px solid #C8D1E8 !important;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px !important;
            opacity: 1 !important;

            &.is-selected {
                background-color: rgba(129, 185, 255, 0.22) !important;
                color: #1A74E2 !important;
                border: 0px !important;
            }
        }
    }

    .rsm-arrow-button {
        border: 0px !important;
        padding: 0 !important;
        width: 18px !important;
        height: 12px !important;
        margin: 24px 0px 10px !important;
    }

    .rsm-date-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #172434;
        margin: 15px 0 4px !important;

        @media screen and (max-width:600px) {
            font-size: 16px;
        }

    }

    .react-calendar {
        font-family: inherit !important;
        min-height: auto !important;
        padding: 20px 0 !important;

        .day-tile {
            width: 48px !important;
            height: 48px !important;
            border-radius: 50% !important;
            border: none;
            outline: none;

            @media screen and (max-width:600px) {
                width: 44px !important;
                height: 44px !important;
            }
        }

        button {
            border: 0px !important;
        }

        abbr {
            font-weight: 500 !important;
            font-size: 14px !important;

            @media screen and (max-width:600px) {
                font-size: 12px !important;
            }
        }

        &__month-view {
            &__weekdays {
                border-bottom: 1px solid #C8D1E8;
                padding-bottom: 10px !important;

                &__weekday {
                    abbr {
                        color: #172434 !important;
                    }

                    &--weekend {

                        abbr {
                            color: #C8D1E8 !important;
                        }


                    }
                }
            }
        }
    }

}

.react-calendar__tile:disabled.day-tile {
    color: #C8D1E8 !important;
}

.react-calendar__tile--active.day-tile:hover {
    color: #172434 !important
}

.active-day-tile::after {
    content: none !important;
    background-color: #1A74E2;
}

.react-calendar__tile--now.day-tile {
    background-color: rgba(214, 224, 244, 0.5) !important;
    color: #172434 !important
}

.react-calendar__tile--active.day-tile,
.react-calendar__tile--active.day-tile:hover {
    background-color: #1A74E2 !important;
    color: #ffffff !important;
}

.active-day-tile:hover {
    opacity: 1 !important;
}

.go135427820 {
    background: #C8D1E8 !important;
    margin: 0 24px 0 !important;
    width: 1px !important;
    height: auto !important;

    @media screen and (max-width:767px) {
        margin: 0 !important;
        width: auto !important;
        height: 1px !important;
    }
}
.go2225988090{
    overflow-y: auto !important;
}

.react-calendar__tile:disabled.day-tile {
    background-color: #ffffff !important;
    color: #C8D1E8 !important;
    border: 0px !important;
}

.react-calendar__tile:disabled.day-tile::after {
    border: 0px !important;
}

.demo-calendar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    width: 95%;
    margin: 0 auto;
    background-color: #ffffff;
    margin-top: 10px;
    padding: 44px;

    @media screen and (max-width:1440px) {
        padding: 24px;

    }

    @media screen and (max-width:1200px) {
        width: 100%;
    }

    @media screen and (max-width:600px) {
        width: 100%;
        padding: 24px 12px;
    }

    .demo-calendar-inner {
        max-width: 790px;
        margin: 0 auto;

        .demo-calendar-header {
            display: flex;
            flex-direction: row;
            align-items:center;
            justify-content: flex-start;
            gap: 8px;
            position: relative;

            .back-btn {
                min-width: unset;
                height: auto;
                background-color: transparent;
                padding: 0 !important;
                position: absolute;
                left: -40px;
                top: 8px;
            }
        }

    }

    .title {
        font-weight: 500;
        font-size: 28px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #021021;
        margin-bottom: 5px;

        @media screen and (max-width:600px) {
            font-size: 20px;
        }

    }

    .subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #8899B4;

        @media screen and (max-width:600px) {
            font-size: 14px;
        }
    }

    .demo-session {
        background-color: rgba(236, 244, 254, 0.54);
        border-radius: 8px;
        padding: 20px;

        p {

            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #021021;
            margin-bottom: 14px;
        }

        ul {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
                display: flex;
                flex-direction: row;
                gap: 12px;
                align-items: center;

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: 0.01em;
                    color: #2E3B4B;

                }
            }
        }
    }

    .demo-footer {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 24px;

        @media screen and (max-width:600px) {
            margin-top: 20px;
        }

        .btn {
            border-radius: 8px;
            background-color: #1A74E2;
            color: #ffffff;
            font-size: 14px;
            line-height: 20px;
            height: auto;
            min-width: unset;
            padding: 12px 25px;
            width: 165px;

            @media screen and (max-width:600px) {
                width: 100%;
            }
        }
    }

    .thank-you-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        min-height: 650px;

        .title {
            margin-top: 27px;
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #021021;
        }

        .back-link {
            padding: 12.5px 13.5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            border-radius: 8px;
            background-color: #1A74E2;
        }

        .sub-detail {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #8899B4;
            margin-bottom: 24px;
            max-width: 334px;

            a {
                text-decoration: underline;
                color: #1A74E2;
            }

            .btn {
                color: #1A74E2;
                min-width: unset;
                padding: 0;
                height: auto;
                background-color: transparent !important;
            }
        }
    }
}