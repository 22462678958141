@import "./user-cart-dropdown";
@import "./cart-item";

.user-cart {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;

    .cart-counter {
        position: absolute;
        @include displayFlex();
        content: " ";
        background-color: $color-secondary;
        @include fontStyles($color-light, 10px, normal);
        border-radius: 50px;
        top: -5px;
    right: -13px;
    padding: 3px 5px;
    min-width: 21px;
    min-height: 20px;
   @media screen and (max-width:600px) {
    font-size: 10px;
    top: -3px;
    right: -10px;
    padding: 2px 5px;
    min-width: 12px;
    min-height: 13px;
   }
   @media screen and (max-width:390px) {
    font-size: 9px;
    top:-5px;
   }
    
    }

    .cart-icon {
        cursor: pointer;
    }
}

 .product-img-link{
    background-color: transparent !important;
    padding:0 !important;
    height: auto !important;
    width: unset !important;
    min-width: unset;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
 }