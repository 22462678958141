.qr-code-screen {
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    padding: 35px 0;
    background-repeat: no-repeat;

    .screen-inner-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .main-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 14px;

            .sub-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #021A37;

                @media screen and (max-width:600px) {
                    max-width: 373px;
                }

            }
        }

        .img-section {
            img {
                width: 188px;
                height: 165px;

            }
        }

    }
}

.activate-pom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 100px;
    min-height: 95vh;

    @media screen and (max-width:1200px) {
        justify-content: center;
    }



    .activate-pom-form {
        background-color: #ffffff;
        border-radius: 8px;
        display: flex;
        min-width: 478px;
        flex-direction: column;
        align-items: center;
        padding: 44px 55px 24px;
        max-width: 525px;
        width: 514px;
        position: relative;

        .back-button-arrow {

            position: absolute;
            left: 24px;
            height: auto;
            min-width: unset;
            top: 30px;
        }

        @media screen and (max-width:600px) {
            max-width: 100%;
            min-width: unset;
            padding: 20px 15px;
            width: 100%;
        }

        .form-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #172434;
            margin: 24px 0;

            @media screen and (max-width:600px) {
                font-size: 20px;
            }
        }

        .MuiStep-root {
            padding: 0;
        }

        .MuiStepConnector-line {
            border-color: #8899B4
        }

        .Mui-completed,
        .Mui-active {
            .MuiStepConnector-line {
                border-color: $color-secondary;
            }
        }

        .MuiStepConnector-root {
            position: absolute;
            top: 18px;
            left: calc(-50% + 18px);
            right: calc(50% + 18px);
        }

        .MuiStepIcon-root {
            width: 36px;
            height: 36px;
            color: #8899B4;
            cursor: none;
            pointer-events: none;

            &.Mui-active {
                color: $color-secondary;
                cursor: none;
                pointer-events: none;
            }
        }

        .MuiStepIcon-text {
            fill: #ffffff;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
        }

        .MuiSvgIcon-root {
            &.Mui-completed {
                color: $color-secondary;

            }
        }

        .MuiStepLabel-label {
            margin-top: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #021021;
            font-family: inherit;

            @media screen and (max-width:600px) {
                font-size: 12px;
            }
        }
    }
}

.activation-login {
    .img-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 44px 0;

        img {
            width: 188px;
            height: 165px;

        }
    }

    .onboarding-banner {
        min-height: auto;
        background-image: none !important;

        .form-section {
            padding: 0;
            min-height: auto;

            .form-card {
                width: 100%;
                border-radius: 0;
                background-color: none;
                padding: 0;
z-index: 0;
                .redirection-link {
                    color: $color-secondary;
                    margin-left: 4px;
                    padding: 0;
                    height: auto;
                    background: none;
                    box-shadow: none;
                    border: none;
                    outline: none;
                    min-width: unset;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                   position: relative;
                   &::before{
                    position: absolute;
                    bottom: 0.5px;
                    width: 100%;
                    height: 1px;
                    background-color: #1A74E2;
                    content: " ";
                   }
                }

                .logo-section,
                .title,
                .detail {
                    display: none;
                }

            }
        }
    }
}

.activation-imei {
    .img-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 44px 0;

        img {
            width: auto;
            height: auto;
        }
    }

    .add-another-pom {
        margin: 3px 0;
        position: relative;

        .trash-btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            height: auto;
            min-width: unset;

        }
    }

    .more-imei-fields {
        max-height: 74rem;
        overflow-y: auto;

    }

    .more-imei-fields::-webkit-scrollbar {
        display: none;
    }

    .imei-warning-msg {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 100%;
        justify-content: flex-start;

        span {
            color: #2E3B4B;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            flex: 1;
        }


    }

    .imei-section-footer {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 15px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #A0B0D0;


        .btn {
            width: 100%;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 10px;
            line-height: 20px;
            border: 1px solid #1A74E2;
            color: #1A74E2;
            height: auto;

            &:disabled {
                svg {
                    path {
                        stroke: #BDC9E2;
                    }
                }

                color:#BDC9E2;
                border: 1px solid #BDC9E2;
            }

            &.btn-secondary {
                background-color: $color-secondary;
                color: #ffffff;
                border: 1px solid $color-secondary;
            }
        }
    }
}

.activation-subscription-plans {
    .add-devices-payment {
        .stepper-header {
            display: none;
        }
    }

    .stepper-header {
        margin-top: 24px;



        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #2E3B4B;

        }
    }

    .plans-footer {
        .add-bulk-btn {
            display: none;
        }

        .btn {
            background-color: $color-secondary;
            color: #ffffff;
            border-radius: 8px;
            width: 100%;
            margin-top: 24px;
            height: auto;
        }
    }

    .renew-plan-footer {
        p {
            font-size: 14px;
        }
    }

    .bulk-stpper-main-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        background: #EFFBFF;
        border-radius: 10px;
        gap: 12px;
        padding: 10px 7px 10px 13px;
        margin: 24px 0 0;

        p {
            flex: 1;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #2E3B4B;


            span {
                color: $color-secondary;
            }
        }
    }

    .bulk-devices-footer {
        border: 0;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;

        .MuiButton-root {
            @media screen and (max-width:575px) {
                width: 100% !important;
            }
        }


        .spinner-circle {
            height: 47px;
        }
    }

    .poms-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-top: 24px;
        width: 100%;
        justify-content: space-between;

        .pom-listing {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            list-style-type: none;
            padding: 0;
            gap: 10px;
            flex-wrap: wrap;

            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                svg,
                img {
                    width: 33px;
                    height: 33px;
                }

                span {

                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    color: #172434;



                }
            }
        }

        .main-header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            justify-content: space-between;
            gap: 4px;

            .details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
            }

            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #2E3B4B;
            }

            span {

                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #8899B4;
                max-width: 254px;

                @media screen and (max-width:600px) {
                    font-size: 12px;
                    max-width: 200px;

                }

            }

            .edit-pom {
                display: flex;
                align-items: center;
                padding: 0;
                height: auto;
                gap: 4px;

                span {
                    max-width: unset !important;
                }

            }
        }
    }
}

.edit-pom-modal {
    background-color: #F6F8FA;
    border-radius: 8px;
    padding: 20px 25px;
    max-width: 85%;
    width: 480px;

    .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 14px;

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #01091D;


        }
    }

    .body {
        padding: 20px 20px 10px 20px;
        background-color: #ffffff;
        border-radius: 8px 8px 0px 0px;
        max-height: 35rem;
        overflow-y: auto;

        .edit-name-field {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        .pom-label {
            display: flex;
            flex-direction: row;
            gap: 4px;

            span {
                color: #8899B4;
            }
        }
    }

    .footer {
        border-radius: 0px 0px 8px 8px;
        background-color: #ffffff;
        padding: 15px 20px 20px;
        border-top: 1px solid #AAB6BC;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        button {
            background-color: $color-secondary;
            font-size: 14px;
            color: #ffffff;
            font-weight: 500;
            border-radius: 8px;
            outline: none;
            border: none;
            height: auto;
            line-height: 20px;
            min-width: unset;
            padding: 12px 25px;
        }
    }
}

.error-message {
    font-size: 12px;
    color: #FF0000;
    font-weight: 500;
    text-align: center;
}

.qr-thankyou-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 35px 25px;
    min-height: 80vh;
    background-color: #ffffff;
    width: 514px;
    border-radius: 8px;

    @media screen and (max-width:600px) {
        width: 90%;
    }

    .header {
        margin: 28px 0 8px;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #021021;
    }

    .details {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #8899B4;
        max-width: 334px;

        a {
            color: $color-secondary;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            text-decoration: underline;
        }
    }

    .home-link {
        background-color: $color-secondary;
        color: #ffffff;
        border-radius: 8px;
        padding: 12px 25px;
        margin: 20px 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        height: auto;
        min-width: unset;

    }
}

.qr-register-grid {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    align-items: flex-start;
    &.names-grid{
        @media screen and (max-width:600px) {
            flex-direction: column;
            gap:0;
        }
    }
    
}

.error-field {
    input {
        border: 1px solid #F13737;
        background-color: #FEF6F6 !important;
        color: #F13737 !important;
    }

}

.logged-in-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;

    .user-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        margin-bottom: 14px;

        p {

            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #021021;

        }

        span {

            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            text-align: center;

            color: #4F5661;
            max-width: 368px;

        }
    }

    .account-btn {
        height: auto;
        min-height: auto;
        border: 1px solid #1A74E2;
        color: #1A74E2;
        border-radius: 8px;
        width: 100%;
        padding: 12px 25px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        background: transparent;
        box-shadow: none;

        &.swicth-account {
            background-color: #1A74E2;
            color: #ffffff;
        }
    }
}

.promo-code-copy {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #2E3B4B;
    }

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #8899B4;
        @media screen and (max-width:425px) {
            font-size: 13px;
        }

    }
}