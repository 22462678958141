.device-main-sidebar{
    background-color: $color-light;
    width:47px;
    position: absolute;
    right:25px;
    top:32px;
    border-radius: 25px;
    padding:0 5px 15px;
    @media screen and (max-width:767px) {
        right:10px;
    }
    @media screen and (max-height:561px) {
        top:10px;
        padding:0 5px 3px;
        width:43px;
    }
    @media screen and (max-height:450px) {
        max-height: 123px;
        overflow: auto;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    @media screen and (max-width:600px) {
        top:10px;
        width:43px;
        padding:0 5px 3px !important;
    }
}