.no-tracker-found{
    background-color: $color-light;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    height: 40vh;
    margin-bottom: 20px;
    @media screen and (max-width:500px) {
        height: 35vh;
    }
    p{ 
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color: #021021;
        max-width: 338px;
        text-align: center;
        margin:10px 0;
        @media screen and (max-width:500px) {
            font-size: 16px;
            max-width: 90%;
        }
    }
    span{ 
        font-weight: 500;
        font-size: 14px;
        line-height: 19.07px;
        color: #8899B4;
        max-width: 338px;
        text-align: center;
        @media screen and (max-width:500px) {
            font-size: 12px;
            max-width: 90%;
        }
    }
    .shop-link{
        background-color: $color-secondary;
        padding:12px 24px;
        border:none;
        text-decoration: none;
        border-radius: 30px;
        display: flex;
        align-items: center;
        margin-top:8px;
        gap: 8px;
    
        span{
            font-size: 14px;
            line-height: 140%;
            color:$color-light;
            font-weight: 500;
            margin-right: 5px;
        }
    }
}