.get-started-container {
    background-color: #ffffff;
}

.get-started-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 70px 0;
    @media screen and (max-width:991px) {
        padding: 30px 0;
    }
    
    .header {

        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: #131313;
        
        @media screen and (max-width:600px) {
            font-size: 22px;
            line-height: 34px;
        }
    }

    .detail {
        font-weight: 500;
        font-size: 20px;
        line-height: 34px;
        text-align: center;
        color: #131313;
        margin-top: 10px;
        width: 1378px;
        max-width: 90%;
        @media screen and (max-width:991px) {
            font-size: 16px;
            line-height: 24px;
        }
         
    }

    .btn-section {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-top: 30px;
        flex-direction: row;
        @media screen and (max-width:600px) {
            flex-direction: column;
            width: 100%;
        }
    }

    .shop-now {
        padding: 12px 0;
        background: #1A74E2;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
        text-decoration: none;
        flex-direction: column;
        width: 200px;
        height: 58px;;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1A74E2;
        @media screen and (max-width:600px) {
           width: 100%;
        }
    }

    .contct-us {
        padding: 11px 0;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #1A74E2;
        border: 1px solid #1A74E2;
        text-decoration: none;
        width: 200px;
        height: 58px;;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:600px) {
            width: 100%;
         }
    }
}