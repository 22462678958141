.selected-group-content {
    width: 447px;
    background-color: #F6F8FA;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    gap: 14px;
    border: none;
    outline: none;
    max-width: 90%;
    @media screen and (max-width: 500px) {
        max-width: 85%;
    }
 
     .modal-header{
        width:100%;
        .modal-header-inner{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        .close-btn{
            padding:0;
            height: auto;
            min-width: unset;
        }
        .modal-title{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #021021;


        }
        .device-search-header{
            width: 100%;
            margin-bottom: 10px;
            @media screen and (max-width:600px) {
                @include displayFlex($justifyContent:flex-start);
            }
            @include displayFlex($justifyContent:space-between);
            .search-input{
                border: 1px solid $color-body-text-3;
                background-color: $color-light;
                border-radius: 30px;
                flex:1;
                width:100%;
                padding:10px 10px 10px 3px;
                position:relative;
                .MuiInputBase-root{
                    width:100%;
                }
                @media screen and (max-width:600px) {
                   width:82%;
                   flex:1;
                }
                @media screen and (max-width:550px) {
                    width:78%;
                }
                @media screen and (max-width:470px) {
                    width:65%;
                }
                @include displayFlex($justifyContent:space-between);
                input{
                    width:122px;
                    @include fontStyles($fontSize:14px,$lineHeight:normal);
                    padding-left: 6px;
                    padding-bottom: 0;
                    flex:1;
                    font-family: "Montserrat", sans-serif;
                    @media screen and (max-width:360px) {
                        width:85px;
                    }
                    ::placeholder{
                        color: $color-body-text-3;
                    }
                    
                }
                ::placeholder{
                    color: $color-body-text-3;
                    opacity: 1;
                    font-size: 14px;
                }
               

            }
        }
     }

     .modal-body{
        background-color: $color-light;
        border-radius: 20px;
        padding:10px;
        width: 100%;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        
        justify-content: space-between;
        @media screen and (max-width:820px) {
            flex-direction: column;
         
        }
        .MuiFormControlLabel-label{
            font-size: 14px;
            color:#8899B4;
        }
     }
     .modal-footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        
        justify-content: space-between;

        button{
            height: auto;
            min-width: auto;
        }
        .clear-btn{
            padding:0;
            font-size: 14px;
            font-weight: 500;
            margin:10px 0px 0px;
            color:#8899B4;
            background-color: transparent;
            box-shadow: none;
        }
        .continue-btn{
            padding:0;
            font-size: 14px;
            font-weight: 500;
            margin:10px 0px 0px;
            color:$color-light;
            background-color: $color-secondary;
            padding:9px 25px;
            border-radius: 8px;
            box-shadow: none;
        }
     }
    .group-section{
        width: 100%;
        max-height: 45vh;
        overflow-y:auto;
        overflow-x: hidden;
         
        .MuiAccordionSummary-root {
            min-height: unset;
            height: auto;
            margin: 0;
            display: flex;
            align-items: center;
            &.Mui-expanded{
                margin:5px 0 0;
                padding: 0 8px;
            }
        }
        .MuiAccordionSummary-content{
            margin: 0 0 5px;
        }
        .MuiPaper-root {
            width: 100%;
            background-color: transparent;
            padding: 8px ;
            box-shadow: none;
            margin: 2px 0 2px;
            border-radius: 0;
            border-bottom: 1px solid #C8D1E8;
            .accordion-header{
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
            }
            .MuiFormControlLabel-root {
                margin-left: 0;
            }
             
        }
        .MuiAccordionDetails-root {
            padding: 0;
        }
        >div{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
          
            position: relative;
           
        }
         
        .group-names-details{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
             
            .group-name{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                
            }
            img{
                width:40px;
                height: 40px;
            }
            .group-details{
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                p{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    color: #172434; 
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 135px;
                }
                span{
                    font-weight: 500;
                    font-size: 14px;
                    margin-top:2px;
                    line-height: 150%;
                    color: #8899B4;  
                }
            }
        }
    }
    .tracker-section{
        width: 100%;
        max-height: 165px;
        overflow-y:auto;
        overflow-x: hidden;
        border-radius: 10px;
        background-color: #F6F8FA;
        margin-top:5px;
        .tracker-list{
            display: flex;
            flex-direction: column;
            align-items: center;
           
            overflow: auto;
            margin:8px 0;
            
            .tracker-details{
                display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: space-between;
            align-items: center;
            padding:3px 0;
            border-bottom: 1px solid #C8D1E8;
            img{
                margin-right: 10px;
                width: 40px;
                height: 40px;
            }
            .tracker-name{
                display: flex;
                flex-direction: row;
                align-items: center;
    
                p{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    color: #172434;
                }
                
            }
            }
        }
    }
    .tracker-listing-modal{
        display: flex;
        flex-direction: column;
        padding: 20px;
        
        .all-trackers-listing{
            display: flex;
            flex-direction: column;
            max-height: 45vh;
            overflow: auto;
        
            .remember-me-checkbox{
                padding: 10px 0;
                border-bottom: 1px solid #C8D1E8;
                margin: 0;
                .MuiFormControlLabel-label {
                    flex:1;
                }
               
            }
            .group-names-details{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: space-between;
               
                .group-name{
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    &.tracker-name{
                        align-items: center;
                    }
                }
                img{
                    width:33px;
                    height: 33px;
                }
                .group-details{
                    p{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 150%;
                        color: #172434; 
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 135px;
                    }
                    span{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 150%;
                        color: #8899B4;  
                    }
                }
            }
        }
    }

    
}