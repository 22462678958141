.scanning-page {
    --section_radius:8px;
    --section_background:#F6F8FA;
    --section_padding:25px;
    --section_font_size:14px;
    --section_font_weight:500;
    background: var(--section_color) !important;
}
.scanning-container {
    padding: 20px 50px;
    .header {
        margin-bottom: 30px;
        h1 {
          font-size: 25px;  
        }
        span {
            font-size: var(--section_font_size);
        }
    }
    .MuiBox-root {
        border: none;
        .MuiTabs-root {
            border-bottom: none;
            background: #fff;
            border-radius: var(--section_radius);
            margin-bottom: 15px;
            .MuiTabs-flexContainer {
                justify-content: space-around;
                button {
                    font-size: var(--section_font_size);
                    color: #1A74E2;
                }
            }
            .MuiTabs-indicator {
                background-color: #1A74E2 !important;
            }
        }
    }
    .MuiTabPanel-root {
        padding: 0 !important;
    }
    .scan-imei-tab-content,
    .pending-imei-tab-content,
    .upgraded-imei-tab-content {
        .manual-input-section{
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: var(--section_radius);
            padding: var(--section_padding);
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 12px;
            label {
                font-size: var(--section_font_size);
                font-weight: var(--section_font_weight);
                margin-bottom: 5px;
            }
            textarea {
                height: 108px;
                border-radius: var(--section_radius);
                border: none;
                background: var(--section_background);
                margin-bottom: 10px;
                resize: none;
                padding: 10px;
                &:focus {
                    outline: none;
                }
            }
            .command-btn-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                button {
                    margin-left: auto;
                }
                .error-text {
                    font-size: 12px;
                    color: #F13737;
                }
                .spinner-circle {
                    width: 258px;
                }
            }
        }
        .all-imei-section,
        .pending-imei-section {
            border-radius: var(--section_radius);
            background:#fff;
            margin-bottom: 12px;
            .heading-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: var(--section_padding);
                h3 {
                    font-size: 18px;
                    font-weight: 500;
                }
                button {
                    font-size: 16px;
                    color: #4F5661;
                    border-color: #4F5661;
                    border-radius: 8px;
                }
            }
            .tablelike {
                .tablelike__header {
                    .tablelike__row {
                        display: flex;
                        justify-content: space-between;
                        margin: 0 35px;
                        p {
                            flex-grow: 1;
                        }
                    }
                }
                .tablelike__body {
                    padding: 0 25px 34px;
                    .checkbox-head {
                        padding-left: 5px;
                       span {
                        &.Mui-checked {
                            color: #1A74E2 !important;
                        }
                       }
                    }
                    .record-list {
                        padding: 0;
                        list-style: none;
                        height: 450px;
                        overflow-y: auto;
                        li {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border: 1px solid #DDE2E4;
                            border-radius: 14px;
                            padding: 0px 8px 0 4px;
                            min-height: 64px;
                            gap: 27px;
                            margin-bottom: 10px;
                            .imei-section {
                                width: 50%;
                                span {
                                    font-size: var(--section_font_size);
                                    font-weight: var(--section_font_weight);
                                    &.Mui-checked {
                                        color: #1A74E2 !important;
                                    }
                                }
                            }
                            .del-icon {
                                margin-right: auto;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .imei-tab-footer {
            background: #fff;
            padding: 15px;
            border-radius: var(--section_radius);
            display: flex;
            justify-content: space-between;
            button {
                font-size: var(--section_font_size);
                font-weight: var(--section_font_weight);
                min-width: 113px;
                border-radius: 8px;
                text-transform: capitalize;
                &.del-btn {
                    color: #1A74E2 !important;
                    border-color: #1A74E2 !important;   
                }
                &.save-btn {
                    background-color: #1A74E2 !important;
                }
            }
        }
    }
    .pending-imei-tab-content {
        .pending-imei-section {
            background: #fff;
            border-radius: var(--section_radius);
            margin-bottom: 30px;
            .heading-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .heading-wrapper {
                    display: flex;
                    svg {
                        font-size: 24px;
                        cursor: pointer;
                    }
                }
                h4 {
                    font-size: 18px;
                    font-weight: 500;
                    margin-right: 5px;
                }
                .input-wrapper {
                    position: relative;
                    input {
                        border: 1px solid #bdc9e2;
                        width: 270px;
                        color: #8899b4;
                        min-height: 25px;
                        border-radius: 50px;
                        padding: 10px 0 10px 40px;
                        font-size: 12px;
                        padding-left: 10px !important;
                        &:focus,
                        &:active,
                        &:focus-visible {
                            outline:none;
                        }
                    }
                    svg {
                        position: absolute;
                        left: 11px;
                        top: 11px;
                        font-size: 25px;
                        color: #a0b0d0;
                    }
                    .chips-input {
                        .MuiInputBase-root {
                            padding: 0;
                            background: none;
                            max-width: 270px;
                            input {
                                &:focus,
                                &:active,
                                &:focus {
                                    background: none;
                                }
                            }
                            .MuiButtonBase-root {
                                .MuiSvgIcon-root {
                                    right: -10px;
                                    top: -9px;
                                    left: unset;
                                    font-size: 16px;
                                }
                            }
                            .MuiAutocomplete-tag {
                                max-height: 25px;
                                .MuiChip-label {
                                    font-size: 10px;
                                }
                            }
                        }
                        .MuiAutocomplete-endAdornment {
                            right: 22px;
                            bottom: 7px;
                            top: unset;
                            .MuiButtonBase-root {
                                .MuiSvgIcon-root {
                                    font-size: 21px !important;
                                    right: -15px !important;
                                    top: -14px !important;
                                }
                            }
                        }
                    }
                }
                .custom-pom-switch {
                    .MuiSwitch-root {
                        margin-right: 6px;
                    }
                }
                .filter-section {
                    display: flex;
                    align-items: center;
                    position: relative;
                    svg {
                        position: absolute;
                        right: 11px;
                        font-size: 23px;
                        font-weight: 100;
                        color: #8899b4;
                    } 
                    label {
                        font-size: var(--section_font_size);
                        font-weight: var(--section_font_weight);
                        margin-right: 12px;
                    }
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        height: 46px;
                        width: 147px;
                        border-radius: 50px;
                        padding: 0 10px;
                        font-size: var(--section_font_size);
                        font-weight: var(--section_font_weight);
                        border-color: #8899b4;
                        color: #4f5661;
                        &:focus,
                        &:active,
                        &:focus-visible {
                            outline:none;
                        }
                        &::-ms-expand {
                            display: none;
                        }
                    }
                    .MuiInputBase-root:has(> .MuiSelect-select) {
                        border-radius: 27px;
                        svg {
                            top: 13px;
                            right: 5px;
                        }
                    }
                }
            }
            .tablelike {
                .tablelike__body {
                    .record-list {
                        .imei-section {
                            width: inherit !important;
                        }
                        .status-pill {
                            font-size: var(--section_font_size);
                            font-weight: var(--section_font_weight);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 98px;
                            min-height: 34px;
                            border-radius: 50px;
                            text-transform: capitalize;
                            &.pending {
                                background: #FFF4E5;
                                color: #FF8F00;
                            }
                            &.failed {
                                background: #feefef;
                                color: #F13737;
                            }
                        }
                        .action-icons {
                            display: flex;
                            align-items: center;
                            svg {
                                margin-right: 6px;
                                cursor: pointer;
                            }
                        }
                        .send-text {
                            font-size: var(--section_font_size);
                            font-weight: var(--section_font_weight);
                            color: #1A74E2;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .pagination-section {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            .MuiPagination-ul {
                li  {
                    button {
                        color: #1a74e2 !important;
                        border-color: #1a74e2 !important;
                        border-radius: 10px;
                        &.Mui-selected {
                            color: #fff !important;
                            background-color: #1a74e2 !important;
                        }
                        &:hover {
                            color: #fff !important;
                            background-color: #1a74e2 !important;
                        }
                    }
                }
            }
        }
    }
    .upgraded-imei-tab-content {
        .tablelike {
            .tablelike__body {
                .record-list {
                    li {
                        padding: 0px 15px 0 22px !important;
                        .action-icons {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
.history-modal-wrapper,
.del-imei-alert-wrapper {
    background: #f6f8fa;
    border: none !important;
    border-radius: 8px;
    padding: 15px;
    .modal-heading {
        margin-bottom: 10px;
        h2 {
            font-size: 18px;
            font-weight: 500;
        }
        .close-btn {
            position: absolute;
            top: 8px;
            right: 8px;
            svg {
                font-size: 20px;
            }
        }
    }
    .modal-body {
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        .search-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 22px;
            .input-wrapper {
                position: relative;
                input {
                    border: 1px solid #bdc9e2;
                    width: 270px;
                    color: #8899b4;
                    min-height: 46px;
                    border-radius: 50px;
                    padding: 10px 0 10px 40px;
                    &:focus,
                    &:active,
                    &:focus-visible {
                        outline:none;
                    }
                }
                svg {
                    position: absolute;
                    left: 11px;
                    top: 11px;
                    font-size: 25px;
                    color: #a0b0d0;
                }
            }
            .date-picker-history-modal {
                width: 270px;
                height: 46px;
                &>button {
                    min-height: 46px;
                    P {
                        color: #172434;
                    }
                }
            }
        }
        .list-detail {
            max-height: 450px;
            overflow-y: auto;
            overflow-x: hidden;
            .accordian-section {
                box-shadow: none;
                margin: 0 !important;
                &:before {
                    background-color:unset !important ;
                }
                .MuiAccordionSummary-content {
                    padding-left: 12px;
                    font-size: 16px;
                    font-weight: 500;
                    position: relative;
                    svg {
                        top: -4px;
                        position: absolute;
                        left: -20px;
                        font-size: 29px;
                        color: #8899B4;
                    }
                }
                .Mui-expanded {
                    min-height: 35px !important;
                    margin: 0 !important;
                    .MuiAccordionSummary-content {
                        svg {
                            transform: rotate(180deg);
                            color: #172434;
                        }
                    }
                }
                .actions-list {
                    padding-left: 16px;
                    list-style: none;
                    border-left: 1px solid #BDC9E2;
                    margin-left: 14px;
                    li {
                        position: relative;
                        margin-bottom: 19px;
                        &>span {
                            font-size: 12px;
                            color: #8899B4;
                            display: inline-flex;
                        }
                        p {
                            font-size: 14px;
                            font-weight: 500;
                            padding-right: 4px;
                            line-break: anywhere;
                            span {
                                text-transform: capitalize;
                                font-weight: 600;
                                display: inline-flex;
                                padding-left: 5px;
                                &.pending {
                                    color: #FF8F00;
                                }
                                &.failed {
                                    color: #F13737;
                                }
                                &.success {
                                    color: #287f36;
                                }
                            }
                        }
                        &:after {
                            position: absolute;
                            content: "";
                            border-radius: 50px;
                            width: 11px;
                            height: 11px;
                            background: #1A74E2;
                            top: -2px;
                            border: 2px solid #d9eef8;
                            left: -22px;
                        }
                    }
                }
                .MuiAccordionDetails-root {
                    padding: 0 !important;
                }
            }
        }
    }
}
.del-imei-alert-wrapper {
    background: #fff !important;
    .modal-body{
        .inner-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 20px;
            p {
                font-size: 20px;
                font-weight: 500;
            }
            .action-btn-section {
                display: flex;
                align-items: center;
                button {
                    min-width: 129px;
                    height: 44px;
                    margin-right: 5px;
                }
            }
        }
    }
}