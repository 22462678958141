.existing-address{
    background-color: $address-form;
    border-radius: 8px;
    padding:15px 10px;
    margin: 0 25px;
    @media screen and (max-width:585px) {
        margin: 0 15px;;
    }
    .MuiFormControl-root{
        width:100%;
        @include displayFlex(row,space-between,center);
    }
    .MuiFormGroup-root{
        width:100%;
        @include displayFlex(row,space-between,center);
    }
    .address-radiobox{
        @include displayFlex(row,flex-start,flex-start);
    }
    .address-radiobox-section{
        @include displayFlex(row,space-between,center);
        width:100%;
        border-bottom: 1px solid rgba($border-color,0.4);
        padding:15px 20px 15px 0;
        @media screen and (max-width:1440px) {
            align-items: flex-start;
        }
        @media screen and (max-width:425px) {
            padding:15px 10px;
        }
        .address-action-btn{
            @include displayFlex(row,center,center);
            @media screen and (max-width:585px) {
                @include displayFlex(row,flex-end,center);
                width:100%;
                margin-top:10px;
            }
            .btn{
                padding:0;
                height: auto;
                width: auto;
                min-width: unset;
                margin-left: 15px;
            }
        }
        .MuiFormHelperText-root {
            margin-top:0;
            max-width: 485px;
            @media screen and (max-width:1440px) {
                max-width:406px;
            }
            @media screen and (max-width:1199px) {
                max-width:485px;
            }
            @media screen and (max-width:820px) {
                max-width:365px;
            }
            @media screen and (max-width:640px) {
                max-width:265px;
            }
            @media screen and (max-width:585px) {
                max-width:78%;
            }
            @media screen and (max-width:425px){
                max-width: 183px;
                margin: 0;
                margin-left: 8px;
            }
        }
        .name{
            @include fontStyles($color-dark,16px,normal);
            margin-top:0;
        }
        .phone-number,.address{
            @include fontStyles($color-body-text-2,14px,150%);
        }
    }
}

.popup-address-form{
    border-radius: 20px;
    padding:0;
    .MuiFormControlLabel-root{
        margin-top:-3px;
        margin-left:0px;
        margin-bottom: 18px;
        .MuiFormControlLabel-label{
            margin-left: 8px;
            @include fontStyles($color-body-text-2,14px);
             margin-top:2px;
        }
    }
    .address-header{
        margin-bottom: 15px;
        @include displayFlex($justifyContent:space-between);
        p{
           @include fontStyles($fontSize:18px); 
        }
        .close-btn{
            padding:0;
            background: none;
            box-shadow: none;
            min-width: auto;
            height: auto;
        }
    }
    .address-inner-content{
        padding:25px;
        border-radius: 20px;
        background-color: $color-light;
        .MuiGrid-item{
            padding-top:0;
        }
        
    }
    .save-address-btn{
        width: 100%;
        padding:25px  25px 0 ;
        border-top: 1px solid rgba($border-color,0.4);
        margin-top:20px;
    
        @include displayFlex($justifyContent:flex-end,$alignItems:flex-end);
    .btn{
        box-shadow: none;
        @include fontStyles($color-footer-link,14px,150%);
        background: $color-secondary;
        padding:10px 20px;
        height: auto;
        transition: none;
        min-height: unset;  
    }
}
    
}