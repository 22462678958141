

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Montserrat", sans-serif;
}
html, body { height: 100%; }
html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  overflow-x: hidden;
  max-width: 2880px;
  margin: 0 auto !important;
  background-color: #F6F8FA !important;
}

.MuiTypography-root,
.MuiInputLabel-root,
.MuiSelect-select {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

input{
  font-family: "Montserrat", sans-serif;
}

.icon-secondary {
  color: $color-secondary;
  path{
    stroke: $color-secondary;
  }
}

.icon-body-text-color-1 {
  color: $color-body-text-1;
}
.pb-0{
  padding-bottom: 0 !important;
}
.center-with-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.spinner-circle {
  background-color: $color-secondary;
  width: 100%;
  padding: 10.25px 25px;
  border-radius: 8px;
  svg{
    color:#ffffff;
  }
}

.text-error {
  color: #F13737 !important;
      font-size: 12px;
      margin: 2px 0 0  5px ;
      line-height: 125%;
      font-family: inherit;
      font-weight: 500;
}

.custom-phone-input-select {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    color: #021021;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;

    span {
      color: #FF0000;
    }
  }
.special-label{
  display: none;
}
  .text-error {
    font-size: 12px;
  }

  .react-tel-input {
    border:1px solid transparent;
    &:focus-within{
      border:1px solid #1A74E2;
      border-radius: 10px;
    }
    .form-control {
      height: 44px;
      width: 100%;
      border: none;
      padding-left: 62px;
      border-radius: 10px;
    }
    .flag-dropdown {
      border: none;
      border-right: 1px solid rgba(160, 176, 208, 0.20);
      background-color: #f3f5f6;
      padding-right: 10px;
      border-radius: 10px 0 0 10px;

      // &:hover {
      //   background-color: #f3f5f6 !important;
      // }
    }
    .react-tel-input, .selected-flag:hover, .selected-flag:focus, .selected-flag:active {
      background-color: #f3f5f6;
      border-radius: 10px;
    }

    .react-tel-input, .flag-dropdown.open, .selected-flag  {
      background-color: #f3f5f6 !important;
      border-radius: 10px !important;
    }
  } 
}
@media screen and (max-width:600px) {
  #userback_button_container .userback-button-e[wstyle=text] {
    transform: rotate(90deg) translate(50%, -50%) !important;
    transform-origin: 0 !important;
    left: 0;
    right: 0;
    top:20% ;
    width:120px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.MuiInputBase-root{
   
  outline: none;
  &::before,&::after{
     content: none;
  }
}