.not-found-page{
    @include displayFlex(column);
    z-index: 1;
    @media screen and (max-width:600px) {
        max-width: 90%;
        text-align: center;
    } 
    .error-text{
        text-shadow: -10px 2px #FFffff; 
      font-weight: 600;
      font-size: 172.152px;
      line-height: 100%;
      text-align: center;
      text-transform: capitalize;
      
      color: #1A74E2;
      @media screen and (max-width:400px) {
        font-size: 105px;
    } 
    }
    span{
        color: $color-text-secondary;
       
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; 
         
        @media screen and (max-width:600px) {
            font-size: 22px;
           
        } 
    }
    p{
        color: $color-body-text-2;
        font-size: 20px;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 150%; 
        margin:0;
        text-align: center;
        margin-bottom: 20px;
        width: 585px;
        max-width: 90%;
        @media screen and (max-width:600px) {
            font-size: 18px;
        } 
    }
    .back-btn{
        background-color: $color-secondary !important;
        color:$color-light;
        font-size: 14px;
        padding:12px 30px;
        width:fit-content;
        box-shadow: none;
        font-weight: $font-weight-medium ;
        min-height: auto;
        height: auto;
        border-radius: 10px;
        font-family: inherit;
    }
}