@import "./user-menu";

.logged-in-user {
    position: relative;
.logged-in-btn{
    padding:0;
    height:auto;
    min-width: auto;
    width: auto;
    background: none;
    transition: none;
    box-shadow: none;
    img,svg{
        width: 34px;
        height: 34px;
        border-radius: 50px;
        object-fit: contain;
        object-position: center;
        @media screen and (max-width:390px) {
            width: 25px;
            height: 25px;
        }
        
    }
}
    .user-icon {
        cursor: pointer;
       
    }
}
.tour-btn{
    padding:0;
    min-width: unset;
    height: auto;
    text-decoration: none;
    background-color: transparent !important;
    box-shadow: none;
    background: transparent;
    transition: none;
    border: none;
    outline: none;
    transition: none;
    svg{
        @media screen and (max-width:390px) {
            width: 23px;
            height: 23px;        
        }
    }
}
#helpful-link-popover {
    .MuiPaper-root {
        min-width: 150px;
        padding: 11px 20px;
        border-radius: 20px;
        z-index: 9999;
        h4 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            border-bottom: 1px solid #D6E0F4;
            padding-bottom: 10px;
        }
        ul {
            list-style: none;
            li {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: 10px;
                        width: 17px;
                        height: 17px;
                    }
                    &:hover {
                        color: #1A73B7;
                        &.fill {
                            svg {
                                path {
                                    fill: #1A73B7;
                                }
                            }
                        }
                        &.stroke {
                            svg {
                                path {
                                    stroke: #1A73B7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}