.testimonial-activity-modal {
    background-color: #F6F8FA;
    border-radius: 8px;
    padding: 25px 20px;
    width: 903px;
    max-width: 90%;
    box-shadow: 0px 0px 40px -5px #010B2333;
@media screen and (max-width:600px) {
    padding: 15px 12px;
}
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #01091D;
        }
    }

    .modal-body {
        margin-top: 14px;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 30px 24px;
        @media screen and (max-width:600px) {
            padding: 10px;
        }
        .inner-content {
            display: flex;
            flex-direction: column;
            gap: 38px;
            padding:0;
            @media screen and (max-width:600px) {
               gap:20px;
            }
            .search-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                @media screen  and (max-width:767px){
                    flex-direction: column;
                    gap:14px;
                }
                .searchbar {
                    border: 1px solid #BDC9E2;
                    border-radius: 30px;
                    min-width: 270px;
                    padding: 14px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    @media screen  and (max-width:767px){
                      min-width: 100%;
                    }
                    .MuiInputBase-root {
                        flex: 1;
                    }

                    svg {
                        width: 16px;
                        height: 16px;
                    }

                    input {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 500;
                        flex: 1;
                        padding: 0;
                        color: #8899B4;
                        font-family: "Montserrat", sans-serif;
                    }

                    &::placeholder {
                        color: #8899B4;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 500;
                        opacity: 1;
                    }
                }

                .status-container {
                    background-color: #F3F5F6;
                    border-radius: 10px;
                    min-width: 270px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    @media screen  and (max-width:767px){
                        min-width: 100%;
                    }
                    .custom-datepicker {
                        position: absolute;
                        left: -29px;
                        z-index: 1;
                        margin-top: 8px;
                        border-radius: 8px;
                        box-shadow: 0px 0px 40px -5px #010B2333;
                        @media screen  and (max-width:767px){
                           
                            left:0;
                             
                        }
                        .rdrDefinedRangesWrapper {
                            display: none;
                        }
                        .rdrCalendarWrapper{
                            @media screen  and (max-width:390px){
                           
                                max-width: 277px;
                                 
                            }
                        }
                    }

                    .date-picker-container {
                        width: 100%;
                    }

                    .date-picker {
                        width: 100%;
                        min-width: unset;
                        background: transparent;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        color: #172434;
                        box-shadow: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px;
                        @media screen  and (max-width:767px){
    padding:23px 12px;
}
                        p {
                            flex: 1;
                            text-align: left;
                        }

                        svg {
                            width: 22px;
                            height: 22px;
                        }

                    }
                }
            }

            .activity-log-status {
                display: flex;
                flex-direction: column;
                max-height: 60vh;
                overflow-y: auto;
                @media screen and (max-width:600px) {
                   max-height: 51vh;
                }
                .activity-log-accordion {
                    box-shadow: none;
                    margin: 0;

                    &::before {
                        content: none;
                    }

                    .MuiAccordionSummary-root {
                        display: flex;
                        flex-direction: row-reverse;
                        gap: 14px;
                        padding-left: 0;
                    }

                    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                        path {
                            stroke: #172434;
                        }
                    }

                    .panel-header {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        gap: 14px;

                        p {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            color: #172434;
                            flex: none;
                            order: 1;
                            flex-grow: 0;

                        }

                        .status-counter {
                            background-color: #F3F6FC;
                            padding: 5px 8px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 5px;
                            border-radius: 17.5px;

                            svg {
                                display: none;
                            }

                            span {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 150%;
                                color: #8899B4;
                            }

                            &.unread {
                                background-color: rgba(218, 235, 255, 0.32);

                                svg {
                                    display: inline-flex;
                                }

                                span {
                                    color: #1A74E2;
                                }
                            }
                        }
                    }

                    .activity-list {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 34px;
                        border-left: 1px solid #BDC9E2;
                        margin-left: 12px;
                        padding: 20px 0;

                        .day-activity-list {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 3px;
                            padding-left: 17px;
                            position: relative;

                            &::before {
                                position: absolute;
                                left: -5.6px;
                                top: -6px;
                                content: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="4" fill="%231A74E2"/><circle cx="6" cy="6" r="5" stroke="%23008DD2" stroke-opacity="0.15" stroke-width="2"/></svg>');
                            }

                            .time {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 15px;
                                color: #8899B4;
                            }

                            p {

                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: #4F5661;

                                span {
                                    color: #1A74E2;
                                    font-weight: 600;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}