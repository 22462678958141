.hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding: 60px 0 111px;
    color: white;
    background-position: bottom;
    background-image: url("https://d1wp0s07qdz4mg.cloudfront.net/v1/qr-code-bg.webp");
    @media screen and (max-width:991px) {
        padding: 43px 0;
    }
    .header-user {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-image: none;
        padding: 15px 0 25px 0;

        @media screen and (max-width:600px) {
            padding:10px 0 ;
        }
    }

    .hero-section-inner {
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 45px 0 0;
        gap:25px;
flex-wrap: wrap;
@media screen and (max-width:991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:600px) {
    padding:30px  0 0 ;
}
        .hero-section-header-content {
            display: flex;
            flex-direction: column;
            max-width: 690px;
            @media screen and (max-width:1200px) {
                max-width: 600px;
            }
            @media screen and (max-width:991px) {
                width: 100%;
                max-width: 100%;
                align-items: center;
                justify-content: center;
            }
        }

        h2 {
            width: 100%;
            font-weight: 800;
            font-size: 64px;
            line-height: 93.01px;
            color: #3D3D3D;
            letter-spacing: 0.19em;
            @media screen and (max-width:1200px) {
                font-size: 48px;
            }
            span {
                letter-spacing: 0;
                margin: 0 24px;
                @media screen and (max-width:1200px) {
                    margin:0 18px;
                }
            }

            
           
            @media screen and (max-width:600px) {
                font-size: 28px;
                line-height: 35px;
                
                
            }
        }

        .subtitle {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #3D3D3D;
            z-index: 1;
            max-width: 648px;

            @media screen and (max-width:991px) {
                font-size: 18px;
                line-height: 30px;
                max-width: 547px;
                margin: 0;
                width: 100%;
                max-width: 100%;
               
            }
             
            @media screen and (max-width:600px) {
                font-size: 14px;
                line-height: 24px;
                
               
            }
        }

        .hero-section-detail {
            display: flex;
            align-items: flex-end;
            flex: 1;
            justify-content: flex-end;
            @media screen and (max-width:991px) {
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            img {
                height: 409px;
                width: 467px;
                @media screen and (max-width:1440px) {
                    width: 100%;
                    height: auto;
                }
                @media screen and (max-width:991px) {
                    height: 321px;
                    width: 380px;
                
                }
                @media screen and (max-width:600px) {
                    width: 251px;
                    height: 220px;
                }
            }
        }

    }
}

.our-journery-section {
    padding: 80px 0 35px;

    @media screen and (max-width:991px) {
        padding: 43px 0 20px;
    }

    .our-journery {
        display: flex;
        flex-direction: column;
        

        .header {
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            color: #131313;
            margin-bottom: 24px;

            @media screen and (max-width:600px) {
                font-size: 18px;
                line-height: 34px;
            }

            span {
                color: #1A74E2;

                
            }
        }

        .detail {
            font-weight: 400;
            font-size: 22px;
            line-height: 34px;
            color: #131313;
            margin-bottom: 40px;
            @media screen and (max-width:991px) {
                margin-bottom: 32px;
            }
            @media screen and (max-width:600px) {
                font-size: 14px;
                line-height: 24px;
              
            }

            &.centered-align-detail {
                text-align: center;
                max-width: 1249px;
            }
        }

        &.centered-align-journey {
            width: 100%;
            align-items: center;
        }

    }
}

.journery-inner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:1369px) {
        flex-direction: column;
    }

    .text-section {
        max-width: 40%;

        @media screen and (max-width:1369px) {
            max-width: 100%;
        }

        p {
            font-weight: 400;
            font-size: 22px;
            line-height: 34px;
            color: #131313;


            margin-bottom: 40px;
        }
    }

    .img-section {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;

        @media screen and (max-width:1369px) {
            width: 100%;
        }

        .about-peeq-section {
            display: flex;
            flex-direction: row;

        }

        p {
            font-weight: 700;
            font-size: 40px;
            line-height: 54px;
            text-align: center;
            color: #131313;
        }

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #131313;
            max-width: 192px;
        }

        .about-section {
            position: relative;
            top: -112px;
            left: 75px;
        }




    }

}

.about-us-get-started {
     background: 
    linear-gradient(to right bottom, transparent 50%, rgba(251, 252, 254, 1) 0) no-repeat 100% 100% / 5em 4em,
    linear-gradient(180deg, rgba(222, 237, 255, 0.6) 37.15%, rgba(176, 213, 255, 0.6) 100%);
    padding: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 74px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:24px;
    @media screen and (max-width:1200px) {
        padding: 40px 28px;

    }
    @media screen and (max-width:600px) {
        padding: 15px 30px;
        margin-bottom: 35px;

    }
    .text-section {
        display: flex;
        flex-direction: column;
        width: 689px;
        @media screen and (max-width:1369px) {
            width: unset;
            flex: 1;        
        }
        @media screen and (max-width:1200px) {
            width: 422px;
        }
        @media screen and (max-width:991px) {
            width: 100%;
        }

        h2 {
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            color: #131313;
            @media screen and (max-width:600px) {
                font-size: 20px;
            }
        }

        .detail {
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            color: #131313;
            margin: 14px 0;
            @media screen and (max-width:600px) {
                font-size: 14px;
                margin-top:0;
                line-height: 20px;
            }
        }

        .btn-section {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            @media screen and (max-width:775px) {
                flex-direction: column;
                gap:12px;
            }
            .shop-now {
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #FFFFFF;
                background-color: #1A74E2;
                border-radius: 8px;
                border:1px solid #1A74E2;
                height: 50px;
                width:151px;
                display: flex;
                justify-content: center;
                align-items: center;
                @media screen and (max-width:775px) {
                   width: 100%;
                }
            }
            .contact-us{
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #1A74E2;
                background-color: transparent;
                border-radius: 8px;
                border:1px solid #1A74E2;
                height: 50px;
                width:151px;
                display: flex;
                justify-content: center;
                align-items: center;
                @media screen and (max-width:775px) {
                    width: 100%;
                 }
            }
        }
    }
    .img-section{
        @media screen and (max-width:775px) {
            width: 100%;
            display: flex;
            justify-content: center;
        align-items: center;        } 
    }
}