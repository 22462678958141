.addresses-page {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 32px auto;
}

.address-card {
  border: 1px solid rgba($color-border, 0.4);
  padding: 16px 26px;
  border-radius: 21px;
  display: flex;
  justify-content: space-between;
  align-items: start;

  &__details {
    display: flex;
    flex-direction: column;
    gap: 5px;

    h5 {
      font-size: 16px;
      color: $color-dark;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      /* 21px */
      color: #6A6A6A;
      max-width: 277px;
      word-break: break-word;
    -webkit-line-clamp:2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    &.address{
      min-height: 42px;
    }
    }

  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:12px;
    button{
      padding:0;
    }
  }
  .switch-label {
    color: $color-body-text-1;
    margin-top: 11px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    width: 100%;
     
  }
}

.addresses-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 786px) {
    grid-template-columns: 1fr;
  }

  gap: 28px;
}