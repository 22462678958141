.testimonial-settings-container {
    max-width: 95%;

    @media screen and (max-width:1200px) {
        max-width: 100%;
    }
}

.admin-testimonial-page {
    .testimonial-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media screen and (max-width:991px) {
            padding: 0 15px;
            gap: 10px;
            flex-wrap: wrap;
        }

        h2 {
            font-weight: 500;
            font-size: 25px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #021021;
            display: inline-block;
        }

        .action-btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
flex-wrap: wrap;

            .btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                min-width: unset;
                height: auto;
                padding: 12px 15px;
                box-shadow: none;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 500;
                min-width: 99px;
@media screen and (max-width:400px) {
    padding:9px 7px;
    min-width: 90px;
    font-size: 12px;
}
                &__outlined {
                    border: 1px solid #1A74E2;
                    color: #1A74E2;
                    background: transparent;
                }

                &__filled {
                    background-color: #1A74E2;
                    color: #ffffff;
                }
            }
        }
    }

    .testimonial-body {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 28px 0px;
        margin-top: 34px;
         
         
        .MuiFormControl-root:has(.MuiInputBase-root > .MuiSelect-select){
            margin:0;
            @media screen and (max-width:630px) {
                width:100%;
            }
        }
        .MuiInputBase-root:has(> .MuiSelect-select) .MuiInputBase-input{
            text-transform: capitalize;
        }
        .MuiInputBase-root:has(> .MuiSelect-select){
            background-color: #F3F5F6;
            position: relative;
            
            svg{
                display: none;
            }
            &:after{
                position: absolute;
                top:18px;
                right:15px;
                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none"><path d="M1 1L5.5 6.175L10 1" stroke="%23A0B0D0" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
            }
        }

        .search-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 0 36px;
            flex-wrap: wrap;
            gap: 14px;
            @media screen and (max-width:900px) {
                padding:0 20px;
            }
            @media screen and (max-width:630px) {
                gap: 14px;
                flex-direction: column;
                padding: 0 15px;

            }

            .searchbar {
                border: 1px solid #BDC9E2;
                border-radius: 30px;
                min-width: 396px;
                padding: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                @media screen and (max-width:1200px) {
                    min-width: 296px;
                }

                @media screen and (max-width:991px) {
                    min-width: 253px;
                    padding: 13px 12px;

                    @media screen and (max-width:630px) {
                        min-width: 100%;

                    }
                }

                .MuiInputBase-root {
                    flex: 1;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }

                input {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    flex: 1;
                    padding: 0;
                    color: #8899B4;
                    font-family: "Montserrat", sans-serif;
                }

                &::placeholder {
                    color: #8899B4;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                    opacity: 1;
                }
            }

            .custom_select {
                max-width: 238px;
                min-width: 238px;

                @media screen and (max-width:630px) {
                    min-width: 100%;
                    max-width: 100%;

                }

                &__title {
                    display: none;
                }

                .arrow {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }
            }
        }

        .selection-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 14px;
            flex-wrap: wrap;
            @media screen and (max-width:630px) {
                flex-direction: column;
                width: 100%;
            }
        }

        .table-header {
            display: flex;
            flex-direction: row;
            background-color: #F6F8FA;
            padding: 12px 36px;
            margin-top: 32px;
            justify-content: space-between;
            gap: 10px;

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                color: #4F5661;
                width: 14.667%;

                &.review {
                    width: 28%;
                }

                &.hidden {
                    visibility: hidden;
                    width: 7%;
                }
            }
        }

        .table-body {
            padding: 0 21px;
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: flex-start;

            .testimonial-list {
                padding: 12px 15px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
                border: 1px solid rgba(#AAB6BC, 0.4);
                justify-content: space-between;
                border-radius: 8px;
                gap: 10px;

                @media screen and (max-width:1025px) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    padding: 15px;

                }

                .col {
                    width: 14.667%;



                    &__review {
                        width: 28%;

                        p {
                            max-width: 93%;
                            word-break: normal;
                        }
                    }

                    &__sort {
                        p {
                            width: 40px;
                            text-align: center;
                            padding-right: 9px;

                            @media screen and (max-width:1025px) {
                                width: unset;
                                min-width: 40px;
                                text-align: left;
                            }
                        }
                    }

                    &__action-btn {
                        width: 7%;
                        display: flex;
                        flex-direction: row;
                        gap: 4px;
                        justify-content: flex-end;
                        align-items: center;

                        .action-btn-section {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 4px;
                            @media screen and (max-width:1025px) {
                                justify-content: flex-start;
                                flex:1;
                            }
                        }

                        button {
                            padding: 0;
                            background-color: transparent !important;
                        }
                    }
                    &__name{
                        p{
                            // word-break: break-all;
                        }
                    }

                    @media screen and (max-width:1025px) {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .col-header {
                            color: #4F5661;
                            width: 144px;
                            text-align: left;
                            flex: unset;
                            @media screen and (max-width:365px) {
                                width: 121px;
                            }
                        }
                        .status-container,.designation-container,.order-container{
                        flex: 1;
                        }
                        .review-text {

                            flex: 1;

                            p {
                                width: 100%;
                                max-width: 100%;
                                word-break: normal;
                                @media screen and (max-width:630px) {
                                    text-align: left;
                                }
                            }
                        }
                    }

                    &__status {

                        .status {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17.07px;
                            max-width: 80px;
                            min-width: 67px;
                            border-radius: 30px;
                            text-align: center;
                            padding: 7px 11px;

                            @media screen and (max-width:1200px) {
                                max-width: 68px;
                                min-width: 53px;
                                font-size: 12px;
                            }

                            &__Active,
                            &__active {
                                background-color: #DDF3E0;
                                color: #287F36;

                            }

                            &__Inactive,
                            &__inactive {
                                color: #F13737;
                                background-color: #F1373714;

                            }
                            &__Draft,
                            &__draft {
                                color: #FF8F00;
                                background-color: #FF8F001C;

                            }
                        }
                    }
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 150%;
                    color: #172434;

                    @media screen and (max-width:1024px) {
                        flex: 1;
                    }
                }
            }
        }
    }
}