.contact {
    display: flex;
    flex-direction: column;
    
    
    &__follow {
        width: 100px;
        height: 21px;
        font-style: normal;
        font-weight: 500;
        font-size: 13.9695px;
        line-height: 150%;
        /* identical to box height, or 21px */
        text-transform: uppercase;
        color: #BDC9E2;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    &__social {
        display: flex;

        gap: 15px;
        flex-wrap: wrap;


        .social-item {
            display: flex;
            gap: 1.3rem;
            align-items: center;
            @media (max-width: 600px) {
                gap:5px;
                align-items: center;
                img{
                    height: 25px;
                    width: 25px;
                }
            }
        }

        @media (max-width: 991px) {
            gap:20px;
        }
    }

    &__info {
        display: flex;
       
        gap: 15px;
    flex-wrap: wrap;


        .contact-item {
            display: flex;
            gap: 1.3rem;
            align-items: center;
            @media (max-width: 600px) {
               gap:5px;
               align-items: center;
               img{
                height: 25px;
                width: 25px;
               }
            }
            &__info-sub {
                p {
                    margin: 0;
                }
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color:$color-light;
                    opacity: 0.8;;
                    line-height: 17.07px;
                    min-width: 130px;
                    @media (max-width: 600px) {
                        font-size: 12px;
                    }
                }
                .desc {
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                    line-height: 17.07px;
                    @media (max-width: 600px) {
                        font-size: 12px;
                    }
                   
                }
            }
        }

        

        @media (max-width: 991px) {
            gap:20px;
            flex-direction: column;
            flex-wrap: wrap;
        }

        @media (max-width: 470px) {
            flex-direction: column;
        }
    }

    &__description {
        margin: 0;
        margin-top: 2.4rem;
        font-size: 14px;
        line-height:23.9px;
        font-weight: 500;
        width: 100%;
       
        color: #BDC9E2;
        max-width: 353px;
        @media (max-width: 850px) {
            max-width: unset;
        }
        @media (max-width: 600px) {
          font-size: 12px;
          line-height: 17.9px;
        }
    }

    @media (max-width: 1200px) {
        width: 28rem;
    }

    @media (max-width: 850px) {
        width: 45%;
    }

    @media (max-width: 600px) {
        width: 56%;
    }
}