.order-item {
    padding: 15px 40px 30px 22px;
    border-radius: 16px;
    border: 1px solid $color-body-text-3;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-bottom: 21px;
        cursor: pointer;

        .id {
            display: flex;
            align-items: center;
            gap: 27px;
            // margin-right: 260px;
            width: 25%;

            p {
                color: $form-filled-input-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 120%;
            }
        }

        .open {
            transform: rotate(180deg);
        }

        .date {
            color: $color-body-text-2;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            width: 28%;
            gap: 27px;
            display: flex;
            align-items: center;
            p{
                color: $color-body-text-2;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                 margin:0;
            }
        }

        .status-container {
            width: 25%;

            .status {
                border-radius: 30px;
                padding: 7px 22px;
                width: 180px;
                text-transform: capitalize;
                display: flex;
                justify-content: center;
                text-align: center;
                p {
                    font-size: 14px;
                    font-weight: 500;
                }

                @media (max-width: 1100px) {
                    width: max-content;
                }
            }
        }

        .order-total {
            color: $color-order-total;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            width: 25%;
        }

        .pending {
            background-color: $color-order-status-pending-bg;
            color: $color-order-status-pending;
            text-transform: capitalize;
        }

        .not-paid {
            background-color: $color-order-status-not-paid-bg;
            color: $color-order-status-not-paid;
            text-transform: capitalize;
        }

        .deliverd {
            background-color: $color-order-status-deliverd-bg;
            color: $color-order-status-deliverd;
            text-transform: capitalize;
        }
        .Payment.Received, .payment.received{
            background-color: $color-order-status-deliverd-bg;
            color: $color-order-status-deliverd;
            text-transform: capitalize;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        .inner-table-header {
            padding: 20px 41px;
            border-top: 1px solid $color-order-border;
            border-bottom: 1px solid $color-order-border;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
            color: $color-body-text-1;

            .item-name {
                width: 51%;
            }

            .quantity {
                width: 13%;
            }

            .price {
                width: 11.5%;
            }
        }

        .inner-table-body {
            padding: 0 41px;
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            .row {
                margin: 10px 0;
                display: flex;
                align-items: center;

                .item-info {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    // margin-right: 100px;
                    width: 51.5%;

                    img {
                        width: 50px;
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;

                        .name {
                            color: $color-dark;
                            font-weight: 500;
                            font-size: 14px;
                        }

                        .color {
                            color: $color-body-text-1;
                            text-transform: capitalize;

                            span {
                                color: $color-body-text-1;
                                text-transform: capitalize;
                                font-weight: $font-weight-semibold;
                                margin-left: 4px;
                            }
                        }
                    }
                }

                .item-quantity {
                    color: $color-order-total;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                    width: 12.5%;
                }

                .item-price {
                    color: $color-order-total;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    width: 11.5%;
                }

                .item-total {
                    color: $color-order-total;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                }
            }
        }

        .divider {
            height: 1px;
            background-color: $color-order-border;
        }
    }

    .expanded {
        display: block;
    }

    .cloapsed {
        display: none;
    }
}