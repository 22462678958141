@import "./pencil-banner";
@import "./logo";
@import "./logged-in-user/loggedIn-user";
@import "./cart/user-cart";
@import "./drawer-menu";

.header-user {

    padding: 15px 0 25px 0;

    @media screen and (max-width:600px) {
        padding: 10px 0;
    }

    .MuiContainer-root {
        max-width: 95%;
    }


    &.image-header {
        background-color: #ffffff;

    }

    &.dashboard-header {
        background-color: #ffffff;
        padding: 15px 0;

        @media screen and (max-width:600px) {
            padding: 10px 0;
        }
    }

    &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-with-hamburger {
            margin-right: 16px;

            svg {
                height: 15px;
                margin-top: 1px;
            }

            @media (min-width: 630px) {
                display: none;

            }

            button {
                padding: 0;
                min-width: unset;
            }
        }
        svg {
            cursor: pointer;

            &.user-icon,
            &.cart-icon,
            &.notification-icon-svg {
                @media (max-width: 390px) {
                    width: 24px;
                    height: 22px;
                }
            }
        }

        &__nav {
            list-style: none;
            display: flex;
            align-items: center;
            gap: 30px;
            width: 25%;
            justify-content: center;

            @media screen and (max-width:1440px) {
                width: auto;
                flex: 1;
                gap: 20px;
            }

            .nav-item {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 150%;
                color: #000000;
                white-space: nowrap;
                border-bottom: 1px solid transparent;

                &:hover {


                    border-bottom: 1px solid $color-secondary;
                }
            }

            .active {
                color: #000000;
                font-weight: 600;
                border-bottom: 1px solid $color-secondary;
            }

            @media (max-width: 991px) {
                display: none;
            }
        }

        &__icons {
            display: flex;
            align-items: center;
            gap: 12px;
            flex: 1;
            align-items: center;
            justify-content: flex-end;


            @media screen and (max-width:767px) {
                gap: 12px;
                margin-right: 8px;

            }
            @media screen and (max-width:390px) {
                gap:8px;
                margin-right: 7px;
            }


            &--button {
                border: none;
                border-radius: 0;
                cursor: pointer;
                background: none;
                box-shadow: none;
                transition: none;
                min-width: auto;
                box-shadow: none;
                padding: 0;
                height: auto;
                transition: none;
            }

            .activate-link {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                span {
                    font-size: 12px;
                    font-weight: 500;
                    color: #4F5661;
                    line-height: 20px;

                    @media screen and (max-width:605px) {
                        line-height: 14px;
                        font-size: 8px;
                    }
                }

                a {
                    text-decoration: underline;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    color: #1A74E2;

                    @media screen and (max-width:600px) {
                        line-height: 14px;
                        font-size: 10px;
                    }
                }
            }

            .order-link {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                background-color: #1A74E2;
                border-radius: 8px;
                padding: 12px 27.5px;
                border: 1px solid #1A74E2;

                @media screen and (max-width:767px) {
                    padding: 12px 20px;
                    font-size: 14px;
                }

                @media screen and (max-width:605px) {
                    padding: 3px 18px;
                    font-size: 10px;
                }

                @media screen and (max-width:425px) {
                    padding: 3px 10px;
                    font-size: 8px;
                }

            }

            .demo-link {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #1A74E2;
                background: transparent;
                border: 1px solid #1A74E2;
                border-radius: 8px;
                padding: 12px 12px;

                @media screen and (max-width:767px) {
                    padding: 12px 6px;
                    font-size: 14px;
                }

                @media screen and (max-width:605px) {
                    padding: 3px 4px;
                    font-size: 10px;
                }

                @media screen and (max-width:425px) {
                    padding: 3px 2px;
                    font-size: 8px;
                }
            }
        }
    }
}