
.header-logo{
    display: flex;
    text-decoration: none;
        width: auto;
        align-items: flex-start;
        justify-content: flex-start;
        a{
            margin-top: 7px;
        }
     svg{
        max-height: 34px;
        max-width: 101px;
         
        @media (max-width: 600px) {
            width: 68px;
            height: 23px;
        }
        @media (max-width: 390px)   {
            width: 66px;
    height: 21px;
        }
     }
     
    span {
        letter-spacing: 0px;
        color: $color-logo-header;
        opacity: 1;
        font-weight: 600;
        font-size: 2.2rem;
    }
}
