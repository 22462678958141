.new-homepage {
    position: relative;

    .header-user {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
        background-image: none;
        padding: 15px 0 25px 0;

        @media screen and (max-width:600px) {
            padding: 10px 0;
        }
    }

    .hero-section {
        background-image: url("https://d1wp0s07qdz4mg.cloudfront.net/v1/home-hero-section.webp");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        padding: 101px 0 40px;
        color: white;
        background-color: #C8E4FA;
        height: auto;
        background-position: 100% 100%;

        @media screen and (max-width:991px) {
            padding: 60px 0 40px;
        }

        @media screen and (max-width:600px) {
            padding: 35px 0;
        }


        .hero-section-inner {
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .home-page-hero-section {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 94%;
                gap: 24px;
                justify-content: space-between;

                @media screen and (max-width:991px) {
                    flex-wrap: wrap;
                    width: 100%;
                }

                img {
                    height: 354px;
                    width: 404px;

                    @media screen and (max-width:991px) {
                        margin: 0 auto;
                    }

                    @media screen and (max-width:600px) {
                        width: 252px;
                        height: 220px;
                    }
                }

                .hero-section-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .text-stroke-trick {
                font-weight: 800;
                font-size: 64px;
                line-height: 65px;
                text-align: center;
                text-transform: uppercase;
                color: #1A74E2;

                @media screen and (max-width:991px) {
                    font-size: 54px;
                    line-height: 65.83px;
                }

                @media screen and (max-width:600px) {
                    font-size: 34px;
                    line-height: 53.64px;
                }
            }

            h2 {
                font-weight: 800;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 1px;
                text-transform: initial;
                color: #3D3D3D;

                @media screen and (max-width:991px) {
                    font-size: 24px;
                    line-height: 36px;
                }
                @media screen and (max-width:600px) {
                    font-size: 16px;
                    line-height: 19.5px;
                }

                @media screen and (min-width: 2380px) {
                        color: white;
                }
            }

            .subtitle {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #3D3D3D;
                z-index: 1;
                margin-top: 16px;
                max-width: 685px;

                @media screen and (max-width:991px) {
                    font-size: 24px;
                    line-height: 34px;
                }

                @media screen and (max-width:600px) {
                    font-size: 14px;
                    line-height: 19.9px;
                    max-width: 95%;
                }

                @media screen and (min-width: 2380px) {
                    color: white;
                }
            }

            .hero-section-detail {
                display: flex;
                flex-direction: column;
                align-items: center;

                z-index: 1;
                width: 100%;
                position: relative;
                bottom: unset;
            }

            .device-feature {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 60px;
                word-spacing: 1rem;

                @media screen and (max-width:991px) {
                    font-size: 22px;
                    line-height: 34px;
                    margin-top: 30px;
                }

                @media screen and (max-width:600px) {
                    font-size: 16px;
                    line-height: 30px;
                    max-width: 348px;


                }
            }

            .detail-list {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 30px;
                width: 100%;
                justify-content: space-between;

                flex-wrap: wrap;

                @media screen and (max-width:1200px) {
                    align-items: flex-start;
                }

                @media screen and (max-width:600px) {
                    justify-content: center;
                }


                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 16%;

                    @media screen and (max-width:820px) {
                        width: 30%;
                        margin-bottom: 24px;
                    }

                    p {

                        font-weight: 700;
                        font-size: 44px;
                        line-height: 54px;
                        text-align: center;
                        color: #FFFFFF;

                        @media screen and (max-width:991px) {
                            font-size: 32px;
                            line-height: 39px;
                        }

                        @media screen and (max-width:820px) {
                            font-size: 24px;
                            line-height: 29px;
                        }
                    }

                    span {

                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        text-align: center;
                        color: #FFFFFF;

                        @media screen and (max-width:991px) {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 22px;
                            max-width: 105px;

                        }

                        @media screen and (max-width:820px) {
                            font-size: 14px;
                            line-height: 17.07px;
                        }
                    }
                }
            }

            .testimonials {
                list-style-type: none;
                margin: 44px 0;
                width: 99%;

                @media screen and (max-width:991px) {
                    margin: 70px 0 20px;
                }

                @media screen and (max-width:600px) {
                    margin-top: 24px;
                    width: 99%;
                }

                .testimonial-header-tag {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    gap: 6px;
                    margin: 0 auto 26px;
                    flex-wrap: wrap;

                    .rating {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    @media screen and (max-width:600px) {
                        margin: 0 auto 24px;
                    }

                    @media screen and (max-width:600px) {
                        svg {
                            height: 22px;
                            width: 21px;

                            @media screen and (max-width:395px) {
                                height: 16px;
                                width: 18px;
                            }
                        }

                    }

                    .testimonials-ratings {
                        font-weight: 600;
                        font-size: 48px;
                        line-height: 59px;
                        color: #1A74E2;
                        margin: 0;
                        margin-right: 19px;

                        @media screen and (max-width:600px) {
                            font-size: 38px;
                            margin-right: 9px;
                        }

                        @media screen and (max-width:400px) {

                            margin-right: 0px;
                        }
                    }

                    .testimonials-number {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: #F6F8FA;
                        margin: 0;
                        min-width: unset;
                        margin-top: 6px;

                        @media screen and (max-width:600px) {
                            font-size: 14px;
                            margin-top: 3px;
                        }

                    }
                }

                .testimonials-section {
                    background: #51637966;
                    border-radius: 8px;
                    padding: 30px;
                    min-height: 100%;
                    display: inline-flex !important;
                    flex-direction: column;
                    justify-content: space-between;

                    

                    @media screen and (max-width:991px) {
                        padding: 22px 24px;
                    }

                    

                    .quote-section {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;
                        justify-content: space-between;
                        width: 100%;
                    }
                }

                .slick-track {
                    display: flex;
                    gap: 1rem;

                    .slick-slide {
                        height: inherit !important;

                        >div {
                            height: 100%;
                        }
                    }
                }

                .slick-dots li {
                    margin: 0;

                    button {
                        padding: 0;

                        &::before {
                            color: #FFFFFF;
                            font-size: 10px;
                        }
                    }
                }

                .slick-prev {
                    left: -75px;
                    width: 24.23px;
                    height: 32px;
                    top: 33%;

                    @media screen and (max-width:1600px) {
                        left: -35px
                    }

                    @media screen and (max-width:920px) {
                        left: -25px
                    }
                }

                .slick-next {
                    width: 24.23px;
                    height: 32px;
                    top: 33%;
                }

                .slick-prev:before {
                    content: url('data:image/svg+xml,<svg width="17" height="29" viewBox="0 0 17 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.8105 2.60938L2.69626 14.7237L14.8105 26.8379" stroke="%231A74E2" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    opacity: 1;
                }

                .slick-next:before {
                    content: url('data:image/svg+xml,<svg width="17" height="29" viewBox="0 0 17 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.18945 2.60938L14.3037 14.7237L2.18945 26.8379" stroke="%231A74E2" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    opacity: 1;
                }

                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #FFFFFF;
                    margin-bottom: 5px;
                    // display: -webkit-box;
                    // max-width: 100%;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: normal;
                    // -webkit-line-clamp: 1;
                    // -webkit-box-orient: vertical;

                    @media screen and (max-width:991px) {

                        font-size: 18px;
                        line-height: 22px;
                    }
                }

                span {

                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #D6E0F4;
                    margin: 10px 0 24px;
                    min-width: 350px;
                    max-width: 100%;
                    display: block;

                    @media screen and (max-width:1200px) {
                        min-width: unset;
                    }

                    @media screen and (max-width:991px) {

                        font-size: 14px;
                        line-height: 22px;
                    }

                    @media screen and (max-width:920px) {
                        max-width: 90%;
                    }

                    @media screen and (max-width:600px) {
                        max-width: 100%;
                    }
                }

                .profile {
                    margin-top: 10px;

                    p {

                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        color: #FFFFFF;
                        margin: 0;
                        display: -webkit-box;
                        max-width: 185px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;

                        @media screen and (max-width:991px) {
                            font-size: 14px;
                            line-height: 22px;
                        }

                        &.designation {
                            color: #D6E0F4;
                        }
                    }
                }
            }

            .btn-section {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                gap: 14px;
                flex-direction: row;

                @media screen and (max-width:600px) {
                    width: 100%;
                    flex-direction: column;
                }

                .btn {
                    background: #F0F8FF;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    text-transform: capitalize;
                    color: #000000;
                    padding: 12px 0px;
                    width: 200px;
                    height: 58px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width:600px) {
                        width: 100%;
                    }

                    &.shop-link {
                        background-color: #1A74E2;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .feature-section {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 70px 0;

        @media screen and (max-width:991px) {
            padding: 30px 0;
        }

        .feature-section-inner {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        .all-features {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        .footer-section {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .btn-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 14px;

            @media screen and (max-width:600px) {
                width: 100%;
                flex-direction: column;
            }

            .btn {
                width: 200px;
                height: 58px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                text-transform: capitalize;
                background-color: #ffffff;
                border-radius: 8px;
                padding: 12px 0px;
                border: 1px solid #1A74E2;
                color: #1A74E2;
                max-height: unset;

                @media screen and (max-width:600px) {
                    width: 100%;
                }

                &.shop-link {
                    background-color: #1A74E2 !important;
                    color: #ffffff;


                }
            }
        }



        .view-all-featrure-btn {
            height: auto;
            padding: 0;
            margin: 0 auto;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            text-transform: uppercase;
            color: #021021;
            background: none;
            box-shadow: none;
            margin: 0 0 30px 0;
            box-shadow: none;

            @media screen and (max-width:600px) {
                width: 100%;

            }

            span {
                margin-right: 8px;
                text-transform: uppercase;
            }
        }

        .all-fetaures-section {

            width: 100%;

            .header {
                font-weight: 500;
                font-size: 28px;
                line-height: 40px;
                text-align: center;
                color: #131313;
                text-align: center;
                width: 100%;
                margin: 0 0 70px 0;

                @media screen and (max-width:991px) {
                    font-size: 24px;
                    margin-bottom: 30px;
                }

                @media screen and (max-width:600px) {

                    font-weight: 500;
                    font-size: 22px;
                    line-height: 34px;
                    text-align: center;
                    color: #131313;


                }
            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;

                @media screen and (max-width:991px) {
                    justify-content: space-between;
                }

                li {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 25%;
                    margin-bottom: 50px;

                    @media screen and (max-width:1369px) {
                        width: 33.33%;
                    }

                    @media screen and (max-width:991px) {
                        width: 50%;
                        margin-bottom: 30px;
                    }

                    @media screen and (max-width:600px) {
                        width: 44%;
                    }

                    p {

                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #021021;
                        margin: 10px 0;
                    }

                    span {

                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 150%;
                        color: #4F5661;
                        max-width: 280px;
                    }
                }
            }
        }
    }

    .track-solution-section {
        background-color: #01152F;
        background-size: cover;
        padding: 65px 0px;

        @media screen and (max-width:991px) {
            padding: 40px 0;
        }

        @media screen and (max-width:600px) {
            padding: 30px 0;
        }

        .header {

            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 55px;

            @media screen and (max-width:991px) {
                margin-bottom: 32px;
                font-size: 24px;
            }

            @media screen and (max-width:600px) {
                margin-bottom: 24px;
                font-size: 14px;
            }
        }

        .box-inner-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;


            @media screen and (max-width:991px) {
                flex-direction: column;
                align-items: center;
                padding-bottom: 0px;
            }

            .img-section {
                width: 60%;

                img {
                    height: auto;
                }

                @media screen and (max-width:991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }

            .box-text-section {
                display: flex;
                flex-direction: column;
                width: 30%;
                gap: 20px;

                @media screen and (max-width:991px) {
                    width: 100%;
                    align-items: center;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;

                    @media screen and (max-width:991px) {
                        flex-direction: row;
                        width: 100%;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }



                    li {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 30px;
                        flex-wrap: wrap;
                        align-items: flex-start;

                        @media screen and (max-width:1369px) {
                            margin-bottom: 25px;

                        }

                        @media screen and (max-width:991px) {
                            width: 49%;

                        }



                        .text-section {
                            flex: 1;
                            max-width: 318px;

                            @media screen and (min-width:2400px) {
                                max-width: 100%;
                            }

                            @media screen and (max-width:600px) {
                                max-width: 100%;
                            }
                        }

                        p {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 32px;
                            color: #E3E3E3;

                            @media screen and (max-width:600px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }


                    }
                }
            }

            .btn-section {
                display: flex;
                flex-direction: column;
                gap: 14px;
                width: 100%;

                @media screen and (max-width:991px) {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                @media screen and (max-width:600px) {
                    flex-direction: column;
                }

                .btn {
                    background-color: #1A74E2;
                    color: #ffffff;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 200px;
                    height: 58px;
                    text-transform: capitalize;
                    border-radius: 8px;
                    text-decoration: none;
                    padding: 12px 0px;

                    @media screen and (max-width:600px) {
                        width: 100%;
                    }

                    &.book-demo {
                        color: #021021;
                        background: #F0F8FF;
                    }
                }
            }


        }
    }

}