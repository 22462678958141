.admin-drawer {
    padding: 20px 0 0 33px;

    a {
        padding: 12px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 14px;
        font-size: 14px;
        background-color: transparent !important;

        &.Mui-selected {
            color: $color-secondary;

            svg {
                color: $color-secondary;
            }
        }

        .MuiListItemIcon-root {
            min-width: unset;

            svg {
                width: 22px;
                height: 22px;
            }
        }
    }

    .admin-drawer-accordion {
        box-shadow: none;
        margin-bottom: 20px;

        .MuiAccordionSummary-root {
            min-height: auto;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: #8899B4;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            justify-content: flex-start;

            .MuiAccordionSummary-content {
                margin: 0;
                flex-grow: 0;

                &.Mui-expanded {
                    margin: 0;
                }
            }
        }
    }
}