.demo-main-page {
    margin: 20px 0;
    .calendly-inline-widget{
        min-height: 80vh;;
    }
    @media screen  and (max-width:767px){
        margin: 10px 0 30px;
    }
    @media screen  and (max-width:600px){
        margin: 0 0 30px 0;
    }
    .demo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        width: 95%;
        margin: 0 auto;
        @media screen  and (max-width:1200px){
            
            width: 100%;
        }
        @media screen  and (max-width:767px){
            flex-direction: column;
        }
        .demo-form {
            background-color: #ffffff;
            padding: 44px 44px 44px 28px;
            max-width: 65%;
            border-radius: 8px 0 0 8px;
            @media screen and (max-width:1440px) {
                padding:24px 44px 24px 10px  ;
                border-radius: 8px 8px 0 0;
                max-width: 60%;
            }
            @media screen and (max-width:991px) {
                max-width: 50%;
            }
            @media screen  and (max-width:767px){
               max-width: 100%;
               padding: 24px;
            }
            .title {
                font-weight: 500;
                font-size: 28px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #021021;
                padding-left: 16px;
                @media screen and (max-width:767px) {
                    padding-left: 0;
                }
                @media screen and (max-width:600px) {
                    font-size: 20px;
                }
            }

            .form-inner-grid {
                margin: 24px 0;

                .MuiGrid-item {
                    padding-top: 0;
                    @media screen and (max-width:767px) {
                        padding-left: 0;
                        padding-right: 12px;
                    }
                }

                .textarea-grid {
                    label {
                        color: #021021;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 5px;
                        font-family: inherit;

                    }

                    textarea {
                        background-color: #F3F5F6 !important;
                        color: #021A37 !important;
                        font-size: 14px;
                        font-family: "Montserrat", sans-serif;
                        font-weight: 500;
                        margin-top: 8px;
                        border: none;
                        outline: none;
                        padding: 15px;
                        min-height: 144px;
                        border-radius: 10px;

                        &::placeholder {
                            color: $placeholder-color;
                            font-weight: inherit;
                        }
                    }
                }

                .btn-grid {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    align-items: flex-end;

                    .btn {
                        color: #ffffff;
                        background-color: $color-secondary;
                        border-radius: 8px;
                        padding: 12px 25px;
                        height: auto;
                        min-width: unset;
                        width: 118px;
                    }
                }
            }
        }

        .demo-list {
            flex: 1;
            border-radius: 0px 8px 8px 0;
            padding: 44px;
@media screen and (max-width:1440px) {
    padding:24px;
}
@media screen  and (max-width:767px){
    width: 100%;
    border-radius: 0px 0px 8px 8px;
}
            .title {
                font-weight: 500;
                font-size: 24px;
                line-height: 145%;
                text-align: center;
                color: #0B1735;
                width: 100%;
                margin-bottom: 24px;
                @media screen and (max-width:600px) {
                    font-size: 20px;
                    line-height: 29px;
                }
            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;

                li {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: #ffffff;
                    gap: 10px;
                    padding: 12px;
                    border-radius: 8px;

                    .counter {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 29px;
                        height: 29px;
                        background-color: rgba(208, 229, 255, 1);
                        border-radius: 50%;

                        span {
                            color: #1A74E2;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 22.4px;
                        }
                    }

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: 0.01em;
                        color: #2E3B4B;
                        flex:1;
                        @media screen and (max-width:600px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

    }
}