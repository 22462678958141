.renew-plan-popup {
	padding: 15px 25px;
	background-color: $color-main-bg;
	border-radius: 20px;
	width: 100%;
	max-width: 100%;
	box-shadow: 0px 0px 40px -5px rgba(1, 11, 35, 0.2);
	@media screen and (max-width:600px) {
		padding:15px;
	}
	@media screen and (max-width:425px) {
		padding: 15px 10px;
	}

	.renew-plan-header {
		margin-bottom: 5px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.back-btn {
			img {
				transform: rotate(180deg);
				margin-right: 15px;
			}
		}

		p {
			margin: 0rem;
			color: $color-dark;
			font-size: 18px;
			font-weight: 500;
		}
	}

	.renew-plan-title {
		display: flex;
		align-items: center;
		flex-direction: row;
	}

	.renew-plan-body {
		background-color: #ffffff;
		border-radius: 20px;
		padding: 15px 0px;

		@media screen and (max-width:600px) {
			padding: 15px 0;
		}

		.renew-plan-body-inner {
			padding: 0px 25px;

			@media screen and (max-width:600px) {
				padding: 0 15px;
			}
		}

		.title {
			color: $color-dark;
			font-size: 14px;
			margin: 15px 0px;
		}

		.billed-radiobox {
			border: 1px solid $color-body-text-3;
			border-radius: 46px;
			padding: 15px 0px 17px 20px;
			width: 100%;
			position: relative;
			margin-top: 15px;

			.billed-radiobox-inner {
				display: flex;
				flex-direction: column;

				.bill-cancel {
					color: $color-body-text-1;
					font-size: 12px;
					display: block;

					margin-top: 2px;

					@media screen and (max-width:600px) {
						font-size: 12px;
					}

					@media screen and (max-width:400px) {
						font-size: 10px;
					}
				}

				.dollar {
					font-size: 20px;
					font-weight: 500;
					line-height: 29.05px;

					@media screen and (max-width:390px) {
						font-size: 14px;
					}

				}

				.months {
					font-size: 25px;
					font-weight: 700;

					@media screen and (max-width:390px) {
						font-size: 16px;
					}
				}

				.monthly-plan {
					.original-bill {
						font-size: 14px;
						color: #FF0000;
						text-decoration: line-through;
						position: absolute;
						top: -15px;
						right: 0;
					}
				}

				.MuiFormControlLabel-root {
					margin-left: -11px !important;
				}
			}
		}
	}

	.MuiFormControl-root {
		width: 100%;
	}

	.MuiFormControlLabel-label {
		color: $color-text-secondary;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-left: 6px;
		@media screen and (max-width:600px) {
			font-size: 14px !important;
		}
	}

	.MuiButtonBase-root {
		&.MuiRadio-root {

			padding: 0;

		}
	}

	.billed-radiobox-inner {
		.monthly-plan {
			position: absolute;
			right: 25px;
			top: 24px;
			@include fontStyles($color-body-text-2, 10px, normal);
			@media screen and (max-width:600px) {
				right:15px;
			}
			@media screen and (max-width:390px) {
				top: 25px;
				right: 12px;
			}
		}
	}

	.billed-checked-radiobox {
		border: 1px solid $color-secondary !important;
		background-color: $color-secondary;

		.dollar,
		.months,
		.MuiFormControlLabel-label,
		.monthly-plan {
			color: $color-light !important;
		}

		.bill-cancel {
			color: #E9E8E8 !important;
		}
	}

	.plan-cancel-btn {
		padding: 0 10px;
		height: auto;
		font-size: 10px;
		margin-right: 25px;
		margin-top: 3px;
		border-radius: 5px;
		box-shadow: none;
	}

	.renew-plan-footer {
		p {
			font-size: 14px;
			padding-left: 25px;
			padding-right: 25px;

			@media screen and (max-width:600px) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	.btn-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		margin-top: 15px;
		padding-top: 15px;
		padding-left: 25px;
		padding-right: 25px;

		@media screen and (max-width:600px) {
			padding-left: 15px;
			padding-right: 15px;
		}

		border-top:1px solid rgba(170, 182, 188, 0.4);

		.proceed-btn {
			width: fit-content;
			background-color: $color-secondary;
			color: $color-light;
			font-size: 14px;
			padding: 12px 25px;
			height: auto;
			border-radius: 8px;
			text-transform: initial;
			margin-top: 5px;
			width: 212px;
			height:45px;
			.spinner-circle{
				padding:0;
			}
		}
	}


	.safe-checkout {
		margin: 15px 25px;
		display: flex;
		flex-direction: row;
		align-items: center;

		@media screen and (max-width:600px) {
			padding: 0 15px;
			margin:15px 0;
		}

		p {
			@include fontStyles($color-dark, 14px, normal);
			margin: 0;
			margin-left: 7.76px;
		}

		span {
			@include fontStyles($color-secondary, 14px, normal);
		}
	}

}

.renew-plan-popup .renew-plan-header .back-btn,
.renew-plan-popup .renew-plan-header .cross-btn {
	padding: 0;
	box-shadow: none;
	min-width: unset;
}