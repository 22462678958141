@import "./subscription-summary-items";

.orders-page {

    margin: 51px 75px 10px;
    width: 75% !important;
    margin-bottom: 56px;
    flex: 1;

    @media screen and (max-width:1440px) {
        margin: 51px 25px 10px;
    }

    @media screen and (max-width:1200px) {
        margin: 51px 4% 10px;
        width: 60% !important;

    }

    @media screen and (max-width:425px) {
        margin: 46px 3% 10px;
        width: 37% !important;
    }

    .mobile-detail-header {
        background-color: #F6F8FA;
        padding: 14px 15px;

        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #4F5661;
            margin: 0;
        }
    }
    .device-management-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width:600px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .page-header {
            &__title {
                @media screen and (max-width:600px) {
                    margin-bottom: 10px;
                }
            }
        }
        .add-pom-btn {
            min-width: 144px;
            height: 42px;
            font-size: 16px;
            color: #fff;
            background: #1A74E2;
            border-radius: 8px;
            @media screen and (max-width:600px) {
                width: 140px;
                // font-size: 13px;
            }
            svg {
                font-size: 30px;
                // @media screen and (max-width:600px) {
                //     font-size: 22px;
                // }
            }
            span {
                display: inline-flex;
                margin-right: 8px;
            }
        }
    }
    &__filter-container {
        padding: 20px 25px;
        border-radius: 8px;
        background-color: $color-light;
        margin-bottom: 16px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width:950px) {
            padding: 20px 25px;
        }
        .order-search-input {
            border-radius: 8px;
        }
        .status-container {
            display: flex;
            align-items: center;
            gap: 15px;

            .date-picker-container {
                position: relative;
                margin-bottom: 0;
                .date-picker {
                    display: flex;
                    align-items: center;
                    gap: 17px;
                    background-color: $form-textfield-bg;
                    padding: 15px;
                    height: auto;
                    border-radius: 10px;
                    cursor: pointer;
                    min-width: 235px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        color: #A0B0D0;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }

                    svg {
                        width: 14px;

                        path {
                            stroke: #A0B0D0;
                        }
                    }
                }

                .custom-datepicker {
                    position: absolute;
                    // left: -300px;
                    left: 0;
                    top: 48px;
                    z-index: 1000;

                    .rdrDefinedRangesWrapper {
                        display: none;
                    }

                    .rdrDateRangePickerWrapper {
                        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
                    }

                    @media (max-width: 1050px) {
                        width: 100%;

                        .rdrDateRangePickerWrapper {
                            width: 100%;

                            .rdrCalendarWrapper {
                                width: 100%;

                                .rdrMonths {
                                    width: 100%;

                                    .rdrMonth {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            p {
                color: $color-text-secondary;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }

            .MuiFormControl-root {

                .MuiInputBase-root,
                .MuiOutlinedInput-root {
                    height: 41px;
                    width: 249px;
                    border-radius: 10px;
                    background-color: #F3F5F6;
                    border: none;
                    border-radius: 10px;
                    color: #A0B0D0;
                    padding: 10px;
                    font-size: 14px;
                    font-weight: 600;

                    .MuiInputBase-input {
                        padding: 0;
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }

                @media (max-width: 600px) {
                    width: 100%;
                }
            }

            &__time-period-input {
                width: 182px;
                margin: 10px 0px;

                svg {
                    display: none;
                }

                .MuiSelect-select {
                    background-color: $form-textfield-bg !important;
                    border-radius: 10px;
                    max-width: 100% !important;
                    padding: 13px 15px;
                    position: relative;
                    color: $color-body-text-1;

                    &:after {
                        position: absolute;
                        right: 15px;
                        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none"><path d="M10.6667 1.66602L6 6.33268L1.33333 1.66602" stroke="%23A0B0D0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    }

                    input {
                        background-color: $form-textfield-bg !important;
                        color: $form-filled-input-color !important;
                        font-size: 14px;
                        font-weight: $font-weight-medium;
                        box-shadow: none;
                        line-height: 140%;
                        border-radius: 10px;
                        border: none;
                        padding: 12px;
                        outline: none;

                        &::placeholder {
                            color: $placeholder-color;
                        }

                        &:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                            -webkit-text-fill-color: $form-filled-input-color;
                            transition: background-color 5000s ease-in-out 0s;
                            caret-color: $form-filled-input-color !important;

                            &:hover {
                                -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                                -webkit-text-fill-color: $form-filled-input-color;
                                transition: background-color 5000s ease-in-out 0s;
                                caret-color: $form-filled-input-color !important;
                            }

                            &:focus {
                                -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                                -webkit-text-fill-color: $form-filled-input-color;
                                transition: background-color 5000s ease-in-out 0s;
                                caret-color: $form-filled-input-color !important;
                            }
                        }
                    }
                }

                @media (max-width: 600px) {
                    width: 100%;
                }
            }

            @media (max-width: 900px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                width: 100%;
            }
        }

        @media (max-width: 1050px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 14px;

            .order-search-input {
                width: 100%;
            }

            .status-container {
                flex-direction: column;
                width: 100%;

                .date-picker-container {
                    width: 100%;

                    .date-picker {
                        width: 100%;
                        justify-content: space-between;
                    }
                }

                &__time-period-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 856px) {
            padding: 14px 12px 10px 12px;
        }
    }

    &__container {
        background-color: $color-light;
        border-radius: 8px;

        @media (max-width: 600px) {
            border-radius: 20px;
        }

        .no-tracker-found {
            height: 55vh;
        }

        &__title-container {
            padding: 33px 35px 23px;
            color: $color-logo-header;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;

            @media screen and (max-width:767px) {
                font-size: 14px;
            }

            @media (max-width: 856px) {
                padding: 20px 12px 18px;

            }
        }

        .border-bottom {
            border-bottom: 1px solid rgba($border-color, 0.4);
        }

        &__table-container {
            .table-head {
                background: $color-main-bg;
                padding: 15px 70px;
                font-size: 16px;
                font-weight: 500;
                color: $color-body-text-2;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media screen and (max-width:1024px) {
                    font-size: 14px;
                    padding: 15px 30px !important;
                }

                .name {
                    width: 15.5%;
                    font-size: 14px;
                }

                .imei-number {
                    width: 17%;
                    // padding-left: 12px;
                    @media screen and (max-width:1024px) {
                        padding-left: 0px;
                    }
                }

                .date {
                    width: 19%;
                }
                .admin-date {
                    width: 20%;
                }

                .status {
                    width: 17%;
                    @media screen and (max-width:1024px) {
                        width: 12%;
                    }
                }
                .admin-status {
                    width: 16%;
                    @media screen and (max-width:1024px) {
                        width: 16%;
                    }
                }

                .selected-plan {
                    width: 18%;
                    @media screen and (max-width:1024px) {
                        width: 22%;
                    }
                }

                .admin-selected-plan {
                    width: 13%;
                    @media screen and (max-width:1024px) {
                        width: 22%;
                    }
                }

                .plan-option {
                    opacity: 0;
                    width: 0;
                }
            }

            .table-body {
                padding: 25px 25px 35px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                max-height: 72vh;
                overflow: auto;

                @media (max-width: 1024px) {
                    padding: 25px 20px 35px;
                }

                @media (max-width: 950px) {
                    max-height: unset;
                    overflow: auto;
                }

                @media (max-width: 600px) {
                    padding: 20px 12px;

                }
            }
        }
    }
    &.device-management-page {
        .orders-page__container {
            .orders-page__container__title-container {
                padding-top: 5px;
            }
            .orders-page__container__table-container {
                .table-head {
                    padding: 16px 16px 17px 30px !important;
                    margin:0 25px;
                    border-radius: 16px;
                    background: #E8EDF2;
                    .name {
                        width: 16.5%;
                    }
                }
                .table-body {
                    .summary-item {
                        padding: 15px 0px 15px 25px;
                        @media (max-width: 850px) {
                            padding: 19px 0px 0px 15px;
                        }
                    }
                    .summary-row {
                        &.selected-plan {
                            align-items: center;
                        }
                        p {
                            span {
                                line-height: unset;
                            }
                        }
                        .status {
                            border-radius: 8px;
                        }
                        .device-name {
                            img {
                                @media (max-width: 850px) {
                                    display: none;
                                }
                            }
                            p {
                                @media (max-width: 850px) {
                                    max-width:100% !important;
                                }
                                @media (max-width: 374px) {
                                    max-width:80% !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.settings-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin: 33px 0px 0 0;
    flex-wrap: wrap;

    @media (max-width: 1440px) {
        margin: 30px 0;
    }

    @media (max-width: 485px) {
        margin: 15px 0;
        flex-direction: column-reverse;
        gap: 10px;
        justify-content: center;
        align-items: center;

    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #172434;


    }

    .MuiPagination-ul {
        .MuiPaginationItem-icon {
            font-size: 23px;
        }

        .MuiPaginationItem-root {
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            color: rgba(136, 153, 180, 0.5);

            &.Mui-selected {
                color: #172434;
                background-color: transparent;
            }

        }
    }
}