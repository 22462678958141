.device-filters-listing{
    margin: 15px 15px 0;
    .filter-accordion{
        background-color: $color-main-bg;
        border-radius: 10px;
        padding: 0px;
        box-shadow: none;
        margin-bottom: 8px;
        &::before{
            content: none;
        }
        .filter-accordion-summary{
            padding: 15px ;
        }
        .MuiAccordionSummary-content{
            margin:0;
        }
        .filter-accordion-detail{
            padding: 7px 10px 0px;
            margin: 0 5px;
            border-top: 1px solid rgb(200, 209, 232);
            max-height: 12rem;
            overflow-y: auto;
            @media screen and (max-width:600px) {
                max-height: 6rem;
            }
            .filter-details-inner{
                background-color: $color-light;
                padding:6px 15px;
                border-radius: 10px;
                margin-bottom: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                .filter-label-section{
                    display:flex;
                    flex-direction: row;
                    align-items: center;
                    .MuiFormControlLabel-root{
                        margin-right: 8px;
                    }
                    .MuiButtonBase-root{
                        padding-right: 2px;
                    }
                    .MuiFormControlLabel-label{
                        font-size: 14px;
                        margin-left: 8px;
                    }
                    .total-devices {
                        background-color: $filter-bg;;
                        padding: 2px 5px;
                        border-radius: 50%;
                        min-width: 23px;
                        min-height: 23px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span {
                            color: $color-dark;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
    .filter-header{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .filter-header-count{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:8px;
            .selected-status-count{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                min-width: 35px;
                min-height: 17px;
                padding:0 5px;
                background-color: rgba(220, 235, 254, 1);
                gap:4px;
                border-radius: 30px;
                span{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 150%;
                    color:$color-secondary;
                }
                svg{
                    width:7px;
                    height:8px;
                }
                .btn{
                    height: auto;
                    padding:0;
                    min-width: auto;
                }
            }
        }
        .title{
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: $color-text-secondary;
            margin-bottom: 3px;
            display: flex;
            align-items: center;
            span{
                margin-right: 3px;
            }
        }
        .subtitle{
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            color: $color-body-text-1;
        }
    }
}