.group-poms-modal {
    background-color: #F6F8FA;
    width: 450px;
    border-radius: 17px;
    max-width: 90%;
    outline: none;
    border: none;
    padding:20px 25px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #021021;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 312px;

        }
    }

    .body {
        background-color: #FFFFFF;
        border-radius: 20px;
        margin-top: 15px;
        padding: 15px 0;

        .title {
            border-bottom: 1px solid #C8D1E8;
            font-weight: 500;
            padding: 0 15px 15px;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 150%;
            color: #021021;
        }

        .trackers-list {
            max-height: 35rem;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}

.tracker-detail {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid #C8D1E8;
    margin: 0 15px 10px;
@media screen and (max-width:600px) {
    padding: 15px 6px;
    margin: 0 0px 10px;
}
    .tracker-name-section {
        .tracker-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            img {
                width: 33px;
                height: 33px;

            }

            p {
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 210px;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #172434;


            }
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

    }

    .address-section {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 8px;

        p {
            flex: 1;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: #4F5661;


        }
    }
}