.edit-group-form {
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-top: 15px;
    padding: 15px 0;
    margin-bottom: 20px;

    .edit-group-icon {
        .edit-group-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            p {
                color: #021021;
                font-size: 12px;
                font-weight: 500;
                line-height: normal;
                
            }
        }

        .edit-group-map {
            width: 100%;
            height: 78px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url('../../../resources/images/map-image.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 10px;

            img {
                height: 43px;
                width: 43px;
            }
        }
    }
}
.edit-form-body{
    padding: 0 15px;
}
.edit-group-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 15px;
   padding: 15px 15px 0;
   border-top:1px solid rgba(#AAB6BC,0.4);
    .btn {
        height: auto;
        min-width: unset;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        text-transform: capitalize;
        color: #8899B4;
        border: 1px solid #8899B4;
        border-radius: 30px;
        box-shadow: none;
        transition:none;
        
        background: transparent;
        @media screen and (max-width:425px) {
            width: 100%;
        }
    }
    .add-pom{
        border:0;
        outline: none;
        padding:0;
        color:$color-secondary;
    }
    .update-pom{
        background-color:#1A74E2 ;
        color:#ffffff;
        border:none;
       
    }
}

.tracker-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid #C8D1E8;
    margin: 0 15px 10px;
    padding-bottom: 15px;

    .MuiFormControlLabel-root {
        margin: 0;
    }

    .tracker-detail {
        padding: 0;
        border: 0;
        margin: 0;
    }
}