.settings-block {
  background-color: white;
  border-radius: 8px;

  &__header {
    padding: 15px 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($color-border, 0.4);
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    @media screen and (max-width: 786px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px;
    }

    .btn {
      padding: 0;
      justify-self: flex-end;
      white-space: nowrap;
      color:#1A74E2;
      .MuiButton-startIcon{
        @media screen and (max-width: 600px) {
          margin: 0 2px 0 0px;
          svg{
            width: 11px;
            height: 11px;
          }
        }
      }
      @media screen and (max-width: 600px) {
        font-size: 11px;
        gap: 2px;
      }
    }
  }


  &__header-title {
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  &__content--right {
    justify-self: end;
  }

  &__body {
    padding: 32px 25px;
    @media screen and (max-width: 600px) {
      padding: 30px 15px;
    }
  }

  &__footer {
    .btn__spinner{
      margin: 0;
      color:#ffffff;
    }
    display: grid;
    padding: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba($color-border, 0.4);
    .btn{
      background-color: $color-secondary;
      height: auto;
      padding:10px 20px;
      border-radius: 8px;
      font-family: inherit;
      font-size: 14px;
      line-height: 150%;
      font-weight: 500;
      width: 144.484px;
      height: 41px;
      
    }
    &__spinner{
      margin-right: 0;
      .MuiCircularProgress-circle{
        stroke: white;
      }
    }
    .cancel-btn{
      background-color: transparent;
      padding:0;
      width: unset;
    }
  }
}