.form-password-field {

    .password-inner-field {
        background-color: $form-textfield-bg !important;
        background-color: #343844;
        @include displayFlex($justifyContent: space-between);
        border-radius: 10px;
        width: 100%;
        padding: 10px;
        padding-left: 12px;
        margin: 0px;
        border: 1px solid transparent;
        &:focus-within {
            border: 1px solid #1A74E2;
        }

        input {
            padding: 2px;
            font-family: "Montserrat", sans-serif;
            border: 0 !important;
        }

        .MuiInputBase-root {
            flex: 1;

            &::after {
                border: none !important;
                transition: none;
            }
        }
    }
}