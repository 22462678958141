.activate-device-list {
    padding-top:10px;
    .MuiFormGroup-root{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        label{
           margin:0;
        }
    }
    .activate-checkbox{
        .MuiCheckbox-root{
            padding-right:5px ;
            padding-left: 9.25px;
        }
    }
    .device-detail {
        display: flex;
        flex-direction: column;
        >div{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            img{
                width: 33px;
                height: 33px;
                margin-right: 10px;
            }
        }
        .device-name{
            display: flex;
            flex-direction: column;
            align-items: flex-start; 
        }
    .title{
        font-weight: $font-weight-medium;
        font-size: 16px;
        line-height: 150%;
        color: $color-dark;
       margin:5px 0;
    }
    .imei{
        font-size: 14px;
        line-height: 15px;
        color: $color-body-text-1;
        font-weight: 500;
        
    }

    }
    
}
.subscription-plan-body{
    .MuiFormControl-root {
        width: 100%;
    }
    .MuiFormControlLabel-label {
        color: $color-text-secondary;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 6px;
    }
    .MuiButtonBase-root {
        &.MuiRadio-root {
            
           
            padding: 0;
            
        }
    }
    .billed-radiobox {
        border: 1px solid $color-body-text-3;
        border-radius: 46px;
        padding: 15px 0px 17px 20px;
        width: 100%;
        position: relative;
        margin-top: 15px;
        .billed-radiobox-inner {
            display: flex;
            flex-direction: column;
            .bill-cancel {
                color: $color-body-text-1;
                font-size: 14px;
                display: block;
               
                margin-top: 2px;
                @media screen and (max-width:600px) {
                    font-size: 12px;
                }
                @media screen and (max-width:400px) {
                    font-size: 10px;
                }
            }
            .dollar {
                font-size: 22px;
                font-weight: 500;
                line-height: 29.05px;
            }
            .months {
                font-size: 25px;
                font-weight: 700;
            }
            .monthly-plan {
                position: absolute;
                right: 25px;
                top: 24px;
                @include fontStyles($color-body-text-2,10px,normal);
                @media screen and (max-width:600px) {
                    right:15px;
                }
                @media screen and (max-width:390px) {
                    top: 25px;
                    right:12px;
                }
                .original-bill {
                    font-size: 14px;
                    color: #FF0000;
                    text-decoration: line-through;
                    position: absolute;
                    top: -15px;
                    right: 0;
                }
            }
            .MuiFormControlLabel-root {
                margin-left: -11px !important;
            }
        }
    }
    
    .billed-checked-radiobox{
        border: 1px solid $color-secondary !important;
        background-color: $color-secondary;
        .dollar,.months,.MuiFormControlLabel-label,.monthly-plan{
            color:$color-light !important;
        }
        .bill-cancel{
            color:#E9E8E8 !important;
        }
    }
    .plan-cancel-btn{
        padding: 0 10px;
        height: auto;
        font-size: 12px;
        margin-right: 25px;
        margin-top: 10px;
    }
    .safe-checkout {
		margin: 15px 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		p {
		@include fontStyles($color-dark,14px,normal);
			margin: 0;
			margin-left: 7.76px;
		}
		span {
			@include fontStyles($color-secondary,14px,normal);
		}
	}
    .plans-checkbox{
        border-top:1px solid rgba($border-color,0.4);
        padding-top:10px;
        margin-top:10px;
        padding-left: 10px;
        padding-right:20px ;
        width: 100%;
        .MuiFormControlLabel-label{
            @include fontStyles($color-dark,12px,normal);
            font-size: 12px !important;
            @media screen and (max-width:375px) {
                font-size: 11px !important;
            }
        }
    }
}