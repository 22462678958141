.custom_select {
    width: 100%;
    position: relative;

    &__title {
        color: $color-text-secondary;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 5px;
    }
    .select-placeholder-text {
      color: $placeholder-color;
      font-size: 14px;
      font-weight: 500;
    }
    
    option[value=""][disabled] {
     display: none;
    }
     
    select {
        appearance: none;
        -webkit-appearance: none;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        padding: 13px 15px;
        background-color: $form-textfield-bg;
        border: none;
        color:#021A37;
        font-size: 14px;
        font-weight: 500;
        border-radius: 10px;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;

        &:focus {
            outline: none;
        }
      }

      option {
        padding: 15px;
        color:#021A37;
        font-size: 14px;
        font-weight: 500;
      }

      .arrow {
        position: absolute;
        right: 20px;
        top: 41px;
        pointer-events: none;
      }
}
select:invalid {
  color: #A0B0D0;
}