.notification-page-inner{
  background-color: #ffffff;
  border-radius: 30px;
  .page-header-title{
    padding: 33px 25px 23px;
    color: #021021;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    @media screen and (max-width: 856px) {
      padding: 18px 15px;
    }
  }
}
.notifications-page {
  &__filters {
    padding: 20px 25px;
    margin-bottom: 15px;
    background-color: $color-light;
    border-radius: 20px;

    @media screen and (max-width: 1024px) {
      padding: 20px;
    }

    .btn {
      height: auto;
      padding: 8px 15px;
      font-size: 14px;
    }

    .form-textfield {
      margin-bottom: 0;

      input {
        background-color: #F8F8F8 !important;
        padding: 15px;
        color:#4F5661 !important;
      }
    }

    .report-filters {
      padding-top: 0;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 30px;
        justify-content: center;
      }

      .MuiInputBase-root:has(> .MuiSelect-select) {
        border: 1px solid transparent;

        .MuiInputBase-input {
          padding: 15px 24px 15px 18px;
          max-width: 100%;
          position: relative;
          color: #4F5661;

          &::after {
            content: url('data:image/svg+xml,<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1L5.5 5L1 1" stroke="%23A0B0D0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            position: absolute;
            right: 15px;
            top: 17px;
          }
        }

        svg {
          display: none;
        }

        &.Mui-focused {
          border: 1px solid $color-secondary;
        }
      }
    }
  }

  &__listing {
    padding-bottom: 20px;
    margin-bottom: 60px;
  }

  &__listing-header {
    padding: 31px 44px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 786px) {
      padding: 20px;
    }

    h4 {
      font-size: 24px;
      font-weight: 500;

      @media screen and (max-width: 786px) {
        font-size: 14px;
      }
    }

    &__sorting-controls {
      display: flex;
      align-items: center;
      gap: 22px;
      color: $color-dark;

      .MuiInputLabel-root {
        color: $color-dark;
      }

      .MuiInputBase-root {
        width: 150px;
      }
    }

    &--right {
      margin-left: auto;

      .MuiInputBase-root:has(> .MuiSelect-select) {
        border: 1px solid $placeholder-color;
        position: relative;

        &:after {
          position: absolute;
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none"><path d="M1 1L5.5 6.175L10 1" stroke="%238899B4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          right: 15px;
          cursor: pointer;
        }

        .MuiInputBase-input {
          padding: 12px 0 12px 18px;

        }

        svg {
          visibility: hidden;
        }
      }
    }
  }

  .tablelike {
    --cols: 4;
  }
}

.accordion-menu {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 228px;
  border-radius: 8px;
  padding: 12px 0;
  background-color: #ffffff !important;
  @media screen and (max-width:787px) {
    width: 100%;
  }
 .MuiPaper-root {
  box-shadow: none;
  margin-top: 0px;
  background-color: transparent;

  .MuiButtonBase-root {
    padding: 0;
    margin: 0;
    height: auto;
    min-height: unset;

    .MuiAccordionSummary-content {
      margin: 0;
      border-radius: 10px;
      color: #8899B4 !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      background-color: #F8F8F8;
      padding: 15px 23px 15px 18px;
      margin-top: 2px;
      border: 1px solid transparent;

      &.Mui-expanded {
        border: 1px solid $color-secondary;
      }
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    transition: none;

    svg {
      position: absolute;
      right: 15px;
    }

    &.Mui-expanded {
      transition: none;
      transform: rotate(0deg);
    }
  }

  .MuiAccordionDetails-root {
    background-color: #FFFFFF;
    padding: 16px 0px 14px 0px;
    border-radius: 8px;
    margin-top: 6px;
    position: absolute;
    z-index: 2;
    width: 100%;
    box-shadow: 0px 2px 40px -5px #010B2333;

    .accordion-header {
      margin: 0 10px;
      border-bottom: 1px solid #DCDEE0;

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #8899B4;
        margin-top: 14px;
        margin-bottom: 4px;
      }
    }

    .MuiTypography-root {
      flex: 1;
    }

  .MuiFormControlLabel-root {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;
      padding: 5px 0;
    }

    .group-names-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .group-name {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &.tracker-name {
          align-items: center;
        }
      }


    }
  }
}
.notification-accordion{
  padding:  0px;
  background-color: #ffffff !important;
}
  &.Mui-focusVisible {
    background-color: #ffffff;
  }

  .geofence-group-listing {
    display: flex;
    flex-direction: column;
    max-height: 160px;
    overflow-y: auto;
    margin: 0 0px 0 12px;
    width: 90%;
    overflow-x: hidden;
    @media screen  and (max-width: 787px){
      max-height: 168px;
    }
    .MuiButtonBase-root {
      padding-left: 0;
    }
  }

  .MuiFormControlLabel-root {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  
    gap:8px;
  }

  .group-names-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .group-name {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &.tracker-name {
        align-items: center;
      }
    }

    .group-details {

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #4F5661;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 173px;
        @media screen and (max-width: 787px) {
          max-width: 200px;
        }
      }

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #8899B4;
      }
    }
  }

  .search-input {
    border: 1px solid #8899B4;
    background-color: #FFFFFF;
    border-radius: 30px;
    flex: 1;
    margin: 0 12px;
    padding: 10px 10px 10px 3px;
    position: relative;
    margin-bottom: 5px;
    width: 90%;
    .MuiInputBase-root {
      width: 100%;
    }

    @include displayFlex($justifyContent: space-between);

    input {
      width: 122px;
      @include fontStyles($fontSize: 12px, $lineHeight: normal);
      padding-left: 6px;
      padding-bottom: 0;
      flex: 1;
      font-family: "Montserrat", sans-serif;

      @media screen and (max-width:360px) {
        width: 85px;
      }
    }

    ::placeholder {
      color: #8899B4;
      font-weight: 500;
      font-size: 12px;
      opacity: 1;

    }


  }

  .no-result {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #021021;
    margin: 25px auto 25px -12px;
    text-transform: capitalize;
    font-weight: 500;

  }

  .accordion-footer {
    background-color: #ffffff;
    border-top: 1px solid #DCDEE0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 0;
    margin-top: 5px;
    width: 100%;

    .view-all-btn {
      color: $color-secondary;
      font-size: 12px;
      line-height: 18px;
      box-shadow: none;
      background-color: transparent;
      height: auto;
      min-width: unset;
      padding: 0;
    }

    .clear-all-btn {
      color: #8899B4;
      font-size: 12px;
      line-height: 18px;
      min-width: unset;
      box-shadow: none;
      background-color: transparent;
      height: auto;
      padding: 0;
    }
  }
}

.notification-dropdown {
  display: flex;
  flex-direction: column;
  .selected-device-counter{
    background-color: $color-secondary;
    color:#ffffff;
    font-size: 10px;
    
    border-radius: 20px;
    min-width: 32px;
    min-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .single-selection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:8px;flex-direction: row;
    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #4F5661;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 135px;
    }
  }
  .dropdown-btn {
    margin: 0;
    border-radius: 10px;
    color: #8899B4 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    background-color: #F8F8F8;
    padding: 15px 23px 15px 18px !important;
    margin-top: 2px;
    border: 1px solid transparent;
    display: flex;
    justify-content: flex-start;
    position: relative;
    &.expanded{
          border:1px solid $color-secondary;
    }
    &::after {
      content: url('data:image/svg+xml,<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1L5.5 5L1 1" stroke="%23A0B0D0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      position: absolute;
      right: 15px;
      top: 17px;
    }
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

}

.notification-accordion {
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MuiFormControlLabel-root {
  margin: 0;
}
