.faq-category-modal {
    background-color: #F6F8FA;
    border-radius: 8px;
    padding: 25px 20px;
    width: 420px;
    max-width: 90%;
    box-shadow: 0px 0px 40px -5px #010B2333;

    @media screen and (max-width:600px) {
        padding: 15px 12px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #01091D;
        }
    }

    .modal-body {
        margin-top: 14px;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 24px;

        @media screen and (max-width:600px) {
            padding: 20px;
        }

        .inner-content {
            display: flex;
            flex-direction: column;
            gap: 34px;
            padding: 0;

            @media screen and (max-width:600px) {
                gap: 20px;
            }

            .search-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;

                @media screen and (max-width:767px) {
                    flex-direction: column;
                    gap: 14px;
                }

                .searchbar {
                    border: 1px solid #BDC9E2;
                    border-radius: 30px;
                    min-width: 100%;
                    padding: 14px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    .MuiInputBase-root {
                        flex: 1;
                    }

                    svg {
                        width: 16px;
                        height: 16px;
                    }

                    input {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 500;
                        flex: 1;
                        padding: 0;
                        color: #8899B4;
                        font-family: "Montserrat", sans-serif;
                    }

                    &::placeholder {
                        color: #8899B4;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 500;
                        opacity: 1;
                        font-family: inherit;
                    }
                }


            }

            .category-list {
                display: flex;
                padding: 0;
                flex-direction: column;
                list-style-type: none;
                gap: 24px;
                max-height: 285px;
                overflow-x: hidden;
                overflow-y: auto;
            
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    position: relative;
                    .MuiFormControl-root {
                        width: 74%;
                    }
                    .MuiInputBase-root {
                        flex: 1;
                    }

                    input {
                        outline: 0;
                        font-family: "Montserrat", sans-serif;
                        flex: 1;
                        padding: 0px;
                        font-weight: 500;
                        line-height: 17.07px;
                        font-size: 14px;
                        color: #172434;
                    }

                    .action-btn {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row;
                        gap: 6px;

                        button {
                            padding: 0;
                        }
                    }
                }
            }

            .modal-footer {
                border-top: 1px solid rgba(#AAB6BC, 0.4);
                padding-top: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .add-new-category {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                    width: 100%;
                    position: relative;
                    .MuiFormControl-root {
                        width: 67%;
                    }
                    .MuiInputBase-root {
                        flex: 1;
                    }

                    input {
                        background-color: #F3F5F6;
                        border-radius: 10px;
                        outline: 0;
                        border: 1px solid transparent;
                        font-family: "Montserrat", sans-serif;
                        flex: 1;
                        padding: 13px 12px;
                        font-weight: 500;
                        line-height: 19.6px;
                        font-size: 14px;
                        color: #172434;

                        &:focus-within {
                            border: 1px solid #1A74E2;
                        }

                        &::placeholder {
                            opacity: 1;
                            color: #8899B4;
                        }
                    }
                    button {
                        min-height: 48px;
                        position: absolute;
                        right: 0;
                        top: 24px;
                        width: 100px;
                        &:hover {
                            background-color: #1A74E2 !important;
                        }
                    }
                    .spinner-circle {
                        width: 100px;
                        min-height: 47px;
                        position: absolute;
                        right: 0;
                        top: 24px;
                    }
                }
            }

        }
    }
}