.geofences-page{
    .order-item {
        padding: 15px 0px 30px 22px;
        border-radius: 16px;
        border: 1px solid $color-body-text-3;
        @media screen and (max-width:1200px) {
            padding:15px 47px 15px 68px !important;
        }
        @media screen and (max-width:1024px) {
            padding:15px 15px 15px 20px !important;
             
         }
         @media screen and (max-width:856px) {
            padding:15px 15px  0!important;
             
         }
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            @media screen and (max-width:856px) {
               flex-direction: column;
                
            }
            .row{
                width:14%;
                display: flex;
                flex-direction: row;
                align-items: center;
                &.action-btn-row{
                    width:55px;
                    @media screen and (max-width:1024px) {
                        width:unset;
                         
                     }
                     @media screen and (max-width:856px) {
                        width: 100%;
                         
                     }

                   }
                @media screen and (max-width:856px) {
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 15px;
                    position: relative;
                }
              
                 &.id{
                 padding-left: 14px;
                 @media screen and (max-width:1200px) {
                    padding-left: 12px;
                   }
                 @media screen and (max-width:857px) {
                   padding:0;
                  }}
               
                .mobile-header{
                    color: #8899B4;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 200px;
                    
                    @media screen and (min-width:857px) {
                        display: none;
                        
                    }
                    @media screen and (max-width:585px) {
                        font-size: 12px;
                    }
                }
                .geofence-name{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 238px;
                overflow: hidden;
                @media screen and (max-width:1024px) {
                    width: auto;
                }
                svg{
                    margin-right: 10px;
                }
                }
                p {
                    color: $form-filled-input-color;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100px;
                    @media screen and (max-width:585px) {
                        font-size: 12px;
                      
                        text-align: left;
                    }
                }
                .hilghlighted{
                    color: $color-secondary;
                    text-decoration: underline;
                    padding:0;
                    height: auto;
                    font-size: 14px;
                    min-width: auto;
                    font-weight: 500;
                    line-height: 120%;
                    @media screen and (max-width:585px) {
                        font-size: 12px;
                      
                    }
                }
                
                .zero{
                    color:#8899B4;
                    padding:0;
                    height: auto;
                    min-width: auto;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                    @media screen and (max-width:585px) {
                        font-size: 12px;
                    }
                }
            }
             
    
            .open {
                transform: rotate(180deg);
                color:#172434;
                path{
                    stroke:#172434;
                }
            }
            .responsive-arrow{
                margin-right: 10px;
                @media screen and (max-width:856px) {
                    position: absolute;
                    left: -15px;
                    top: 12px;
                    width: 10px;
                    margin-right: 0;
                    height: 10px;
                }
            }
            .date {
                color: $color-body-text-2;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                width: 25%;
                gap: 27px;
                display: flex;
                align-items: center;
                p{
                    color: $color-body-text-2;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                     margin:0;
                }
            }
    
            
            .order-total {
                color: $color-order-total;
                font-size: 16px;
                font-weight: 600;
                line-height: 150%;
                width: 25%;
            }
        }
    
        &__body {
            display: flex;
            flex-direction: column;
            .inner-table-header {
                padding: 20px 41px;
                border-top: 1px solid $color-order-border;
                border-bottom: 1px solid $color-order-border;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                font-size: 14px;
                font-weight: 500;
                line-height: 120%;
                color: $color-body-text-1;
    
                .item-name {
                    width: 51%;
                }
    
                .quantity {
                    width: 13%;
                }
    
                .price {
                    width: 11.5%;
                }
            }
    
            
    
             
        }
    
        .expanded {
            display: block;
        }
    
        .cloapsed {
            display: none;
        }
    }
    }