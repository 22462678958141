.inner-device-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.edit-btn {
		box-shadow: none  !important;
		border-radius: 16px !important;
		color: $color-dark;
		text-transform: capitalize;
		font-weight: 400;
		min-width: auto !important;
		margin-left: 25px;
		padding: 0 !important;
		margin-top: 2px;
		transition: none   !important;
		background-color: transparent   !important;
		img {
			margin-right: 5px;
		}
	}
	.deviceName {
		color: $color-dark;
		font-size: 17px;
		position: relative;
	}
	.my-device-name {
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
        color: $color-dark;
		max-width: 162px;
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		margin-right: 3px;
		@media screen and (max-width:425px) {
			max-width: 154px;
		}
	}
	.device-name-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		.device-img {
			width: 36px;
			height: 36px;
			margin-right: 5px;
			margin-top: 2px;
			margin-left: 5px;
		}
	}
	.status {
		position: absolute;
		top: 2.5px;
		right: -20px;
	}
	.battery-with-percentage {
		&>span {
			display: flex;
			align-items: center;
			&.no-tooltip {
				button {
					cursor: auto;
				}
			}
		}
		span {
			&.normal-battery,
			&.low-battery {
				font-size: 14px;
				font-weight: 500;
				color: #172434;
				display: inline-flex;
				width: 30px;
				line-height: 1;
			}
			&.low-battery {
				color: #EC2D30;
			}
		}
	}
}
