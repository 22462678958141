.summary-item {
    padding: 15px 0px 15px 46px;
    border-radius: 16px;
    border: 1px solid $color-body-text-3;
    @media screen and (max-width:1024px) {
        padding:15px;
    }
    @media screen and (max-width:850px) {
        padding:15px 15px 0px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width:850px) {
            flex-direction: column;
            position: relative;
        }
        .summary-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            width: 18%;

            .mobile-header {
                display: none;
                color: $placeholder-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 120%;
                text-transform: capitalize;
            }
            @media screen and (max-width:850px) {
                width: 100% !important;
                justify-content: space-between;
                margin-bottom: 20px;
                .mobile-header {
                    display: flex;
                    width:43%;
                }
            }
            p {
                color: $form-filled-input-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 120%;
                text-transform: capitalize;
                overflow: hidden;
                max-width: 135px;
                white-space: nowrap;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                @media screen and (max-width:1440px) {
                    max-width: 113px;
                }
                @media screen and (max-width:991px) {
                    max-width: 92px;
                }
                @media screen and (max-width:850px) {
                    width: 55%;
                    max-width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
           .device-name{
            display: flex;
            flex-direction: row;
            gap:8px;
            align-items: center;
            @media screen and (max-width:850px) {
                width: 55%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                p{
                    max-width: 100%;
                    width: unset;
                    display: inline;
                }
            }
           }
        }

        .status-container {
            width: 18%;
            display: flex;
            justify-content: flex-start;

            .status {
                border-radius: 30px;
                text-transform: capitalize;
                display: flex;
                justify-content: center;
                text-align: center;
                padding: 9px 22px;
                @media screen and (max-width:1024px) {
                    padding: 9px 15px;
                }
                @media screen and (max-width:850px) {
                    p{
                        width: auto !important;
                    }
                }
                &.due {
                    background-color: $color-order-status-pending-bg;

                    p {
                        color: $color-order-status-pending;
                    }

                }

                &.expired {
                    background-color: $color-order-status-not-paid-bg;

                    p {
                        color: $color-order-status-not-paid;
                    }

                }

                &.active {
                    background-color: $color-order-status-deliverd-bg;

                    p {
                        color: $color-order-status-deliverd;
                    }

                }

            }
        }

        .selected-plan {
            display: flex;
            align-items: flex-end;
            width: 18%;
            padding-left: 12px;
            @media screen and (max-width:1024px) {
                padding-left: 0;
            }
            p {
                color: $color-body-text-2;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                align-items: flex-end;
                display: flex;
                max-width: unset;
                span {
                    color: $placeholder-color;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 150%;
                }
            }
        }

        .orders-option {
            width: 3%;
            @media screen and (max-width:850px) {
                position: absolute;
                top: 1px;
                right: 0;
                width: auto;
                img{
                    width: 4px;
                    height: 16px;
                }
            }
            .subscription-option{
                position: relative;
                .dots-btn{
                    box-shadow: none;
                    padding:0;
                    height: auto;
                    min-width: unset;
                    @media screen and (max-width:850px) {
                        padding:0 11px;
                    }
                }
                .order-options-list{
                   position: absolute;
                   list-style-type: none;
                   border-radius: 6px;
                    background: $color-light;
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.11);
                    padding:12px 14px;
                    right: 45px;
                    width: 163px;
                    top: 9px;
                    z-index: 1;
                    @media screen and (max-width:850px) {
                        top:18px;
                        right: 0;
                    }
                    button{
                        transition: none;
                        box-shadow: none;
                        background-color: transparent !important;
                        color:#1A74E2;
                        font-size: 12px;
                        font-weight: 500;
                        min-width: unset;
                        width: auto;
                        padding: 0 !important;
                        height: auto;
                        
                    }
                    .cancel-subscription-btn{
                        color: #FF4343 ;
                        margin: 0;
                        margin-top: 15px;
                    }
                }
            }
            
        }



    }

    &__body {
        display: flex;
        flex-direction: column;

        .inner-table-header {
            padding: 20px 41px;
            border-top: 1px solid $color-order-border;
            border-bottom: 1px solid $color-order-border;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
            color: $color-body-text-1;

            .item-name {
                width: 51%;
            }

            .quantity {
                width: 13%;
            }

            .price {
                width: 11.5%;
            }
        }

        .inner-table-body {
            padding: 0 41px;
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            .row {
                margin: 10px 0;
                display: flex;
                align-items: center;

                .item-info {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    // margin-right: 100px;
                    width: 51.5%;

                    img {
                        width: 50px;
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;

                        .name {
                            color: $color-dark;
                            font-weight: 500;
                            font-size: 14px;
                        }

                        .color {
                            color: $color-body-text-1;
                            text-transform: capitalize;

                            span {
                                color: $color-body-text-1;
                                text-transform: capitalize;
                                font-weight: $font-weight-semibold;
                                margin-left: 4px;
                            }
                        }
                    }
                }

                .item-quantity {
                    color: $color-order-total;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                    width: 12.5%;
                }

                .item-price {
                    color: $color-order-total;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    width: 11.5%;
                }

                .item-total {
                    color: $color-order-total;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                }
            }
        }


    }


}