.user-menu {
    position: absolute;
    left: -167px;
    top: 54px;
    width: 254px;
    background-color: $color-light;
    box-shadow: 0px 2px 40px -5px rgba(1, 11, 35, 0.20);
    border-radius: 15px;
    z-index: 9999;
 @media screen and (max-width:600px) {
    top: 44px;
 }
 @media screen and (max-width:390px) {
    top: 38px;
 }
    &__container {
        width: 100%;
        height: 100%;
        padding: 15px 0;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 18px;
            padding-bottom: 11.73px;

            .user-info {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-transform: capitalize;

                svg, img {
                    width: 34px;
                    height: 34px;
                    border-radius: 34px;
                    object-fit: contain;
                    object-position: center;
                }

                p {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                    color:#021021;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    word-break: break-all;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            button {
                width: 60px;
                height: 25px;
                flex-shrink: 0;
                border-radius: 20px;
                background-color: $color-secondary;
                color: $color-light;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;

                &:hover {
                    background-color: $color-secondary;
                }
            }
        }

        &__body {
            padding: 12.27px 21px;

            .title {
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 16px;
                color:#021021;
            }

            .list {
                display: flex;
                flex-direction: column;
                gap: 16px;
                list-style: none;

                li {
                    display: flex;
                    align-items: center;
                   
                    cursor: pointer;
                    transition: all 0.13s ease-in;

                    a {
                        display: flex;
                    align-items: center;
                    gap: 16px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    color: #8899B4;
                    img,svg{
                        width: 18px;
                        height: 18px;
                    }
                    span{
                        gap: 16px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    color: #8899B4;
                    }
                    }

                    &:hover {
                        color: #172434;
                        svg {
                            path {
                                stroke: #172434;
                            }
                        }
                        span{
                            color: #172434;
                        }
                    }
                }

                .active {
                    color: #172434;
                    svg {
                        path {
                            stroke: #172434;
                        }
                    }
                    span{
                        color: #172434;
                    }
                }
            }
        }
.logout-btn{
    background:none;
    box-shadow: none;
    gap: 16px;
    padding:0;
    img,svg{
        width: 18px;
        height: 18px;
    }
    span{
        gap: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #8899B4;
    }
    &:hover{
        span{
            color: #172434;
        }
    }
}
        &__footer {
            padding: 5px 18px;
            padding-bottom: 0;
            display: flex;
            align-items: center;
            gap: 9px;
            color: #8899B4;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            cursor: pointer;

            
            &:hover {
                color: #172434;
                svg {
                    path {
                        stroke: #172434;
                    }
                }
            }
        }

        .divider {
            width: 100%;
            height: 0.996px;
            background-color: rgba(170, 182, 188, 0.4);
        }
    }

    @media (max-width: 700px) {
        left: -163px
    }

    @media (max-width: 700px) {
        left: -193px
    }
    @media (max-width: 600px) {
        left: -189px
    }
}