.select-device-popup {
	padding: 20px  15px 20px 11px;
    background-color: $address-form;
    border-radius: 20px;
    box-shadow:0px 0px 40px -5px rgba(1, 11, 35, 0.2);
	.selected-device-section {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		border: none;
		padding: 0;
		padding-left: 5px;
		padding-bottom: 10px;
		.my-device-img {
			
			width: 48px;
			height: 48px;
			margin-right: 10px;
            img{
                box-shadow: 0px 3px 6px #00000029;
            }
		}
		.change-device-header {
			display: flex;
			flex-direction: column;
			color: #8899B4;
			font-size: 12px;
			font-weight: 500;
			.my-device-name {
				letter-spacing: 0px;
				color: $color-dark;
                font-weight: 500;
                line-height: normal;
				font-size: 16px;
				margin-top: 5px;
			}
		}
	}
	.section-header {
		color: $color-text-secondary;
		font-size: 14px;
		font-weight: 500;
		margin-top: 25px;
		margin-bottom: 25px;
		padding-left: 5px;
	}
	.first-header {
		margin-top: 6px;
	}
	.upload-icon-section {
		.upload-file-btn {
			background-color: #343844;
			width: 55px;
			height: 55px;
			border-radius: 28px !important;
			box-shadow: none !important;
			transition: none !important;
			padding: 0 !important;
			min-width: unset !important;
			margin: 10px 0px;
		}
	}
	.icon-selection {
		.icons-section-body {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
			
			.icons-detail {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0px 7px;
				padding: 0;
				box-shadow: none !important;
				border-radius: unset !important;
				transition: none  !important;
				text-transform: initial;
				font-weight: 400;
				background-color: transparent;
				cursor: pointer;
				margin-bottom: 15px;
				min-width: unset !important;
				height: unset;
				.img-box {
					width: 55px;
					height: 55px;
					position: relative;
					min-width: unset !important;
					img {
						width: 55px;
						height: 55px;
					}
				}
				.selected-device-img {
					border: 1px solid $color-secondary;
					background-color:  rgba($color-secondary,0.13);
					position: absolute;
					left: 5px;
					width: 45px;
					height: 45px;
					text-align: center;
					border-radius: 50%;
					top: 3px;
				}
				.device-title {
					color: $color-body-text-3;
					font-size: 12px;
                    font-weight: 500;
				}
			}
		}
	}
}
.icons-options-body {
	max-height: 30rem;
	overflow: auto;
	border-top:1px solid #E8EDF2;
	border-bottom: 1px solid #E8EDF2;
	padding-top:5px;
}
.cross-btn {
	padding: 0 !important;
	box-shadow: none  !important;
	min-width: unset !important;
	background-color: transparent !important;
	transition: none !important;
}
icon-upload-input {
	margin-top: 10px;
}
.icon-upload-input {
	.file-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
		display: flex;
		justify-content: flex-start;
		width: 100%;
	}
	.upload-btn-wrapper {
		cursor: pointer;
		display: inline-block;
		overflow: hidden;
		position: relative;
		width: 100%;
		input[type=file] {
			cursor: pointer;
			font-size: 14px;
			font-weight: 500;
			left: 0;
			min-height: 106px;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
		}
	}
	.upload-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		img {
			margin-bottom: 10px;
		}
	}
	.upload-file {
		background-color: #343844;
		border: 1px dashed #ffffff !important;
		border-radius: 20px;
		box-shadow: none;
		color: #9b9b9b;
		cursor: pointer;
		height: 96px;
		width: 100%;
		text-transform: initial;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 0 !important;
	}
}
.icon-section-footer {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	padding-top: 1rem;
	.btn {
		font-size: 14px;
		background-color: $color-secondary ;
		text-transform: initial;
		font-weight: 500;
		padding: 10px 25px;
		border-radius: 8px  ;
		height: auto;
        color:$color-light;
		min-width: unset ;
	}
}
