.order-search-input {
    width: 396px;;
    display: flex;
    align-items: center;
    padding:15px;
    border-radius: 30px;
    border: 1px solid $color-body-text-3;
    @media (max-width: 1440px) {
    width: 317px;
}
    input {
        color: $form-filled-input-color;
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 14px;
        font-weight: 500;
        margin-left: 10px;
        width:75%;
        @media (max-width: 425px) {
            font-size: 13px;
        }
        &::placeholder {
            color: $placeholder-color;
        }
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}