.user-profile-btn {
    max-width: 40px;
    height: auto;
    background: transparent;
    box-shadow: none;
    padding: 0rem !important;
    color: transparent;
    margin: 0px !important;
    min-width:unset;
    color: #172434;
    background-color: none !important;
}
.user-profile-btn{
    &.notification-icon{
        position:relative;
    &:after{
    position: absolute;
    content: " " ;
    top:0;
    right: -2px;
    width:12px;
    height:12px;
    border-radius: 50%;
    background-color: $color-secondary;
    @media screen and (max-width:600px) {
        top: 0px;
        right: 1px;
    width: 8px;
    height: 8px;
    }
    }
    }
}

.user-profile-btn img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.notification-dropdown {
    border-radius: 21px;

    & .no-notifications {
        font-size: 1.4rem;
        color: $color-body-text-1;
        max-height: 30rem;
        min-height: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.notification-dropdown .notification-menu {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: white;
}

.notification-dropdown .notification-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba($border-color,0.4);
    padding: 18px 0;
    margin: 0 15px;
}

.notification-dropdown .notification-body {
    width: 100%;
}

.notification-dropdown .notification-body .body-inner-content {
    width: 100%;
}

.notification-dropdown .notification-body .body-inner-content .notification-list {
    display: flex;
    padding: 1rem 2.5rem;
    overflow-y: auto;
    max-height: 30rem;
    min-height: 20rem;
    flex-direction: column;
    overflow-x: hidden;

    &:has(.no-notifications) {
        min-height: 25rem;
        @include displayFlex();
    }
}

.notification-dropdown .notification-body .body-inner-content .notification-list .day-label {
    color: #797E8D;
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
    margin-top: 5px;
}


.notification-dropdown .notification-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.notification-dropdown .notification-footer .footer-inner-content {
    padding:15px 20px;
    border-top: 1px solid rgba($border-color,0.4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.notification-dropdown .notification-header span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.notification-dropdown .notification-footer .footer-inner-content  .MuiCircularProgress-root{
    width: 25px !important;
    height: 25px !important;
    animation: none;
}

.notification-dropdown .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
    width: 345px;
    border-radius: 20px;
    background-color: white;
    overflow: hidden;
    box-shadow:0px 0px 40px -5px rgba(1, 11, 35, 0.2) !important;
    top: 75px !important;
    @media screen and (max-width:600px) {
        top: 54px !important;
    }
    @media screen and (max-width:390px) {
        top: 51px !important;
    }
}

.notification-dropdown button {
    padding: 0 !important;
    box-shadow: none !important;
    min-height: unset !important;
    min-width: unset !important;
    height:unset;
    transition: none;
    background-color: none !important;
}

.notification-dropdown .notification-footer .footer-inner-content .mark-btn {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    color: $color-dark;
    padding: 14px 25px;
    background-color: transparent !important;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.notification-dropdown .notification-footer .footer-inner-content .mark-btn span{
    font-weight: 500;
    
}

.notification-dropdown .notification-footer .footer-inner-content .view-btn {
    border-radius: 21px;
    background: #1A74E2;
    text-transform: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    transition: none;
    box-shadow: none;
    line-height: normal;
    padding: 10px 25px !important;
    height:auto;
}

.notification-dropdown .notification-box .notification-subject {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.notification-dropdown .notification-box .notification-subject .device-detail {
    display: flex;
    align-items: center;
    color: $color-dark;
}

.notification-dropdown .notification-box .notification-subject .device-detail img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.notification-dropdown .notification-box .notification-subject .device-detail span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 244px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.notification-dropdown .notification-box .notification-subject .status {
    position: relative;
    width: 10px;
    height: 10px;
    background-color: #1A74E2;
    border-radius: 50%;
    margin-right: 5px;
}

.notification-dropdown .notification-box .notification-detail {
    border-radius: 12px;
    border: 1px solid $color-body-text-3;
    color: $color-body-text-2;
    margin-bottom: 15px;
    display: flex;
    font-size: 12px;
    padding: 10px 15px;
    margin-top: 7px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row;
}

.notification-dropdown .notification-box .notification-detail .renew-tag {
    border-radius: 14px;
    background: #FFF6E1;
    padding: 3px 14px;
}

.notification-dropdown .notification-box .notification-detail .renew-tag span {
    color: #EEAC03;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notification-dropdown .notification-box .notification-detail p {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    white-space: normal;
    line-height: 150%;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}