.tools-form {
    background-color: #ffffff;
    padding: 30px 25px 116px;
    border-radius: 8px;

    @media screen and (max-width:767px) {
        padding: 24px 16px 36px;
    }

    .form-header {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #021021;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(#C8D1E8, 0.4);
        margin-bottom: 20px;

        @media screen and (max-width:767px) {
            font-size: 14px;
        }
        
    }

    .form-inner-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 136px;
        align-items: flex-start;
        justify-content: flex-start;

        @media screen and (max-width:1200px) {
            justify-content: space-between;
            gap: 46px;

        }

        @media screen and (max-width:991px) {
            flex-wrap: wrap;
            gap: 26px;
        }

        @media screen and (max-width:767px) {
            flex-direction: column;
            gap: 26px;
        }

        .form-fields {
            max-width: 390px;

            @media screen and (max-width:1200px) {
                max-width: 50%;
            }

            @media screen and (max-width:767px) {
                max-width: 100%;
                width: 100%;
            }

            .password-inner-field {
                border: 1px solid #77B0F670;
                background-color: #F3F5F6 !important;

                input {
                    color: #8899B4 !important;
                    font-size: 14px;
                    line-height: 19.6px;

                }

                button {
                    box-shadow: none;
                    background: none;
                }
            }
        }

        .btn-section {
            display: flex;
            justify-content: flex-end;
            margin-top: 3px;

            .spinner-circle {
                height: 44px;
                width: 137px;
            }

            button {
                background-color: #1A74E2;
                color: #ffffff;
                height: 44px;
                width: 137px;
                font-size: 16px;
                line-height: 24px;
                padding: 0 !important;

                @media screen and (max-width:600px) {
                    font-size: 14px;
                }
            }
        }

        .api-link {
            background-color: #F6F8FA;
            border-radius: 8px;
            padding: 24px 35px;
            width: 480px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;

            @media screen and (max-width:1200px) {
                flex: 1;
            }

            @media screen and (max-width:820px) {
                padding: 14px;
            }

            @media screen and (max-width:767px) {
                max-width: 100%;
                width: unset;


            }

            .details {
                font-weight: 500;
                font-size: 14px;
                line-height: 120%;
                color: #8899B4;
            }

            .link-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 11px;
                svg{
                    width: 16px;
                       height: 16px;
                    @media screen and (max-width:600px) {
                       width: 12px;
                       height: 11px;
                    }  
                }
                p {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 130%;
                    letter-spacing: -0.02em;
                    color: #1A74E2;

                    @media screen and (max-width:767px) {
                        font-size: 16px;
                        line-height: 20.8px;
                    }
                }
            }
        }

    }
}